import { CSSProperties, FC } from "react";
import { Colors } from "../../theme/Colors";
import { CompleteStatusIcon } from "../StatusIcons/Complete";
import { PartiallyFailedStatusIcon } from "../StatusIcons/PartiallyFailed";
import { FailedStatusIcon } from "../StatusIcons/Failed";
import { RejectedStatusIcon } from "../StatusIcons/Rejected";
import { InProcessStatusIcon } from "../StatusIcons/InProcess";
import { PreApprovedStatusIcon } from "../StatusIcons/PreApproved";
import { StatusIconLightGray } from "../StatusIcons/StatusIconLightGray";

export enum ProgressBarStepStatus {
    APPROVED,
    PENDING_TASKS,
    FAILED,
    REJECTED,
    IN_PROGRESS,
    PRE_APPROVED,
    LIGHT_GRAY,
    WAITING_APPROVAL
}

interface ProgressBarStates {
    created: ProgressBarStepStatus
    technicalApproval: ProgressBarStepStatus
    financialApproval: ProgressBarStepStatus
    vtsApproval: ProgressBarStepStatus
    deploy: ProgressBarStepStatus
}

interface ProgressBarProps {
    states: ProgressBarStates
}

export const ProgressBar: FC<ProgressBarProps> = ({ states }) => {
    const baseStepStyle: CSSProperties = {
        height: '8px'
    }

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: '0px',
        alignItems: 'center'
    }

    // -- Steps Variables
    let createdStepContent, technicalApprovalStepContent, financialApprovalStepContent, 
        vtsApprovalStepContent, deployStepContent

    // -- Segment Variables
    let firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    let secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    let thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    let fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>

    if(states.created === ProgressBarStepStatus.APPROVED){
        createdStepContent = <CompleteStatusIcon />
    }else if(states.created === ProgressBarStepStatus.FAILED){
        createdStepContent = <FailedStatusIcon />
    }else if(states.created === ProgressBarStepStatus.IN_PROGRESS){
        createdStepContent = <InProcessStatusIcon />
    }else if(states.created === ProgressBarStepStatus.LIGHT_GRAY){
        createdStepContent = <StatusIconLightGray />
    }else if(states.created === ProgressBarStepStatus.PENDING_TASKS){
        createdStepContent = <PartiallyFailedStatusIcon />
    }else if(states.created === ProgressBarStepStatus.PRE_APPROVED){
        createdStepContent = <PreApprovedStatusIcon />
    }else if(states.created === ProgressBarStepStatus.REJECTED){
        createdStepContent = <RejectedStatusIcon />
    }

    if(states.technicalApproval === ProgressBarStepStatus.APPROVED){
        technicalApprovalStepContent = <CompleteStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.COMPLETE_GREEN, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.FAILED){
        technicalApprovalStepContent = <FailedStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.FAILED_RED, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.IN_PROGRESS){
        technicalApprovalStepContent = <InProcessStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.LIGHT_GRAY){
        technicalApprovalStepContent = <StatusIconLightGray />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.PENDING_TASKS){
        technicalApprovalStepContent = <PartiallyFailedStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PARTIALLY_FAILED_ORANGE, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.PRE_APPROVED){
        technicalApprovalStepContent = <PreApprovedStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PRE_APPROVED_GRAY, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.REJECTED){
        technicalApprovalStepContent = <RejectedStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.REJECTED_RED, width: '100%' }}></div>
    }else if(states.technicalApproval === ProgressBarStepStatus.WAITING_APPROVAL){
        technicalApprovalStepContent = <InProcessStatusIcon />
        firstSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }

    if(states.financialApproval === ProgressBarStepStatus.APPROVED){
        financialApprovalStepContent = <CompleteStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.COMPLETE_GREEN, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.FAILED){
        financialApprovalStepContent = <FailedStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.FAILED_RED, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.IN_PROGRESS){
        financialApprovalStepContent = <InProcessStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.LIGHT_GRAY){
        financialApprovalStepContent = <StatusIconLightGray />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.PENDING_TASKS){
        financialApprovalStepContent = <PartiallyFailedStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PARTIALLY_FAILED_ORANGE, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.PRE_APPROVED){
        financialApprovalStepContent = <PreApprovedStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PRE_APPROVED_GRAY, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.REJECTED){
        financialApprovalStepContent = <RejectedStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.REJECTED_RED, width: '100%' }}></div>
    }else if(states.financialApproval === ProgressBarStepStatus.WAITING_APPROVAL){
        financialApprovalStepContent = <InProcessStatusIcon />
        secondSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }

    if(states.vtsApproval === ProgressBarStepStatus.APPROVED){
        vtsApprovalStepContent = <CompleteStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.COMPLETE_GREEN, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.FAILED){
        vtsApprovalStepContent = <FailedStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.FAILED_RED, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.IN_PROGRESS){
        vtsApprovalStepContent = <InProcessStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.LIGHT_GRAY){
        vtsApprovalStepContent = <StatusIconLightGray />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.PENDING_TASKS){
        vtsApprovalStepContent = <PartiallyFailedStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PARTIALLY_FAILED_ORANGE, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.PRE_APPROVED){
        vtsApprovalStepContent = <PreApprovedStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PRE_APPROVED_GRAY, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.REJECTED){
        vtsApprovalStepContent = <RejectedStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.REJECTED_RED, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.WAITING_APPROVAL){
        vtsApprovalStepContent = <InProcessStatusIcon />
        thirdSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }

    if(states.deploy === ProgressBarStepStatus.APPROVED){
        deployStepContent = <CompleteStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.COMPLETE_GREEN, width: '100%' }}></div>
    }else if(states.deploy === ProgressBarStepStatus.FAILED){
        deployStepContent = <FailedStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.FAILED_RED, width: '100%' }}></div>
    }else if(states.deploy === ProgressBarStepStatus.IN_PROGRESS){
        deployStepContent = <InProcessStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }else if(states.deploy === ProgressBarStepStatus.LIGHT_GRAY){
        deployStepContent = <StatusIconLightGray />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.LIGHT_GRAY, width: '100%' }}></div>
    }else if(states.deploy === ProgressBarStepStatus.PENDING_TASKS){
        deployStepContent = <PartiallyFailedStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PARTIALLY_FAILED_ORANGE, width: '100%' }}></div>
    }else if(states.deploy === ProgressBarStepStatus.PRE_APPROVED){
        deployStepContent = <PreApprovedStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.PRE_APPROVED_GRAY, width: '100%' }}></div>
    }else if(states.deploy === ProgressBarStepStatus.REJECTED){
        deployStepContent = <RejectedStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.REJECTED_RED, width: '100%' }}></div>
    }else if(states.vtsApproval === ProgressBarStepStatus.WAITING_APPROVAL){
        deployStepContent = <InProcessStatusIcon />
        fourthSegment = <div style={{ ...baseStepStyle, backgroundColor: Colors.IN_PROGRESS_YELLOW, width: '100%' }}></div>
    }

    return (
        <div style={gridContainer}>
            {/* Created Step */}
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {createdStepContent}
                {firstSegment}
            </div>

            {/* Technical Approval Step */}
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {technicalApprovalStepContent}
                {secondSegment}
            </div>

            {/* Financial Approval Step */}
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {financialApprovalStepContent}
                {thirdSegment}
            </div>

            {/* VTS Approval Step */}
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {vtsApprovalStepContent}
                {fourthSegment}
            </div>

            {/* Deploy Step */}
            <div style={{ display: 'flex', alignItems: 'center'}}>
                {deployStepContent}
            </div>
        </div>
    )
}