import { FC, useState } from "react";
import { Colors } from "../../../theme/Colors";

interface SelectSortByProps extends React.SelectHTMLAttributes<HTMLSelectElement> { }

export const SelectSortBy: FC<SelectSortByProps> = ({ children, ...props }) => {
    const [hovered, setHovered] = useState<boolean>(false);

    return (
        <select
            {...props}
            style={{
                padding: '10px 20px 10px 20px',
                border: `1px solid ${hovered ? Colors.COMPLETE_GREEN : Colors.DARK_BLUE_GRAY}`,
                borderRadius: '6px',
                width: '200px',
                height: '40px'
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            {children}
        </select>
    )
}