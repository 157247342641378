import { gql } from '@apollo/client/core';

export const GET_SERVICE_CATEGORIES_QUERY = gql`
  query GetServiceCategories($category: String) {
    getServiceCategories(
      category: $category
    ) {
      name,
      category,
      description,
      favourite
    }
  }
`;

export const GET_DAY2_OP_CATEGORIES_QUERY = gql`
  query GetDay2OpCategories($category: String) {
    getDay2OpCategories(
      category: $category
    ) {
      name,
      categories,
      description,
      favourite,
      kind
    }
  }
`;

export const GET_FAVORITE_SERVICES_QUERY = gql`
  query GetFavoriteServices {
    getFavoriteServices {
      description
      favourite
      name
    }
  }
`;

export const GET_FAVORITE_DAY2OPS_QUERY = gql`
  query GetFavoriteDay2Ops {
    getFavoriteDay2Ops {
      description
      favourite
      name
    }
  }
`;