import { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { BreadcrumbItem } from "../Header/Breadcrumb";
import { Card } from "../Card";
import { Large } from "../Fonts/Large";
import { ButtonPrimaryFilled } from "../Button/ButtonPrimaryFilled";
import { ItemInCatalog, ItemInCatalogType } from "../Catalog/ItemInCatalog";
import { useQuery } from "@apollo/client";
import { GET_FAVORITE_DAY2OPS_QUERY, GET_FAVORITE_SERVICES_QUERY } from "../../graphql/catalog/queries";
import { POLL_INTERVAL } from "../../utils/constants";
import { LargeUnderline } from "../Fonts/LargeUnderline";
import { Link, useNavigate } from "react-router-dom";
import { ErrorToast } from "../Toasts/ErrorToast";
import { Loading } from "../Dashboard/Loading";
import { Colors } from "../../theme/Colors";
import { Medium2 } from "../Fonts/Medium2";

interface FavoritesProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const Favorites: FC<FavoritesProps> = ({ changeBreadcrumb }) => {
    const navigate = useNavigate();

    const breadcrumbItems = useMemo(() => [
        { label: 'Favorites', link: '/favorites' }
    ], []);

    useEffect(() => {
        changeBreadcrumb(breadcrumbItems);
    }, [breadcrumbItems, changeBreadcrumb]);

    const { loading: loadingServices, error: errorServices, data: dataServices, refetch: refetchServices } =
        useQuery(GET_FAVORITE_SERVICES_QUERY, {
            pollInterval: POLL_INTERVAL
        });

    const { loading: loadingDay2Ops, error: errorDay2Ops, data: dataDay2Ops, refetch: refetchDay2Ops } =
        useQuery(GET_FAVORITE_DAY2OPS_QUERY, {
            pollInterval: POLL_INTERVAL
        });

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '25% 25% 25% 25%',
        justifyContent: 'space-evenly',
        gap: '10px'
    }

    return (
        <div style={{ margin: '10px' }}>
            <Card style={{ padding: '24px 30px 24px 30px', display: 'flex', justifyContent: 'space-between' }}>
                <Large>Favorite Services</Large>
                <ButtonPrimaryFilled onClick={() => navigate('/catalog/services')}>View All</ButtonPrimaryFilled>
            </Card>

            {loadingServices ? (<Loading />) : (
                dataServices && dataServices.getFavoriteServices.length > 0 ? (
                    <div style={{ ...gridContainer, marginTop: '40px', marginLeft: '15px', marginRight: '15px' }}>
                        {dataServices && dataServices.getFavoriteServices.map((e: any) => (
                            <ItemInCatalog data={e} type={ItemInCatalogType.SERVICE} refetch={refetchServices} />
                        ))}
                    </div>
                ) : (
                    errorServices ? <Medium2 style={{
                        textAlign: 'center',
                        color: Colors.REJECTED_RED,
                        borderTop: `1px solid #EEF0F4`,
                        paddingTop: '10px'
                    }}>
                        {errorServices.name} {errorServices.message}</Medium2> :
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                            <LargeUnderline>There are no Favorite Services</LargeUnderline>
                            <Link to={'/catalog/services'}>
                                <LargeUnderline>View All Services</LargeUnderline>
                            </Link>
                        </div>
                )
            )}



            <Card style={{ padding: '24px 30px 24px 30px', marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}>
                <Large>Favorite Day 2 Operations</Large>
                <ButtonPrimaryFilled onClick={() => navigate('/catalog/day2ops')}>View All</ButtonPrimaryFilled>
            </Card>

            {/* Favorite Day2Ops */}
            {loadingDay2Ops ? (<Loading />) : (
                dataDay2Ops && dataDay2Ops.getFavoriteDay2Ops.length > 0 ? (
                    <div style={{ ...gridContainer, marginTop: '40px', marginLeft: '15px', marginRight: '15px' }}>
                        {dataDay2Ops && dataDay2Ops.getFavoriteDay2Ops.map((e: any) => (
                            <ItemInCatalog data={e} type={ItemInCatalogType.DAY_2_OP} refetch={refetchDay2Ops} />
                        ))}
                    </div>
                ) : (
                    errorDay2Ops ? <Medium2 style={{
                        textAlign: 'center',
                        color: Colors.REJECTED_RED,
                        borderTop: `1px solid #EEF0F4`,
                        paddingTop: '10px'
                    }}>
                        {errorDay2Ops.name} {errorDay2Ops.message}</Medium2> :
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                            <LargeUnderline>There are no Favorite Day 2 Operations</LargeUnderline>
                            <Link to={'/catalog/day2ops'}>
                                <LargeUnderline>View All Day 2 Operations</LargeUnderline>
                            </Link>
                        </div>
                )
            )}

        </div>
    )
}