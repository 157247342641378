import { FC } from "react";
import { ButtonProps, ButtonWithHover } from "..";
import { Colors } from "../../../theme/Colors";
import { Small1 } from "../../Fonts/Small1";

export const ButtonPrimaryFilled: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {

    return (
        <ButtonWithHover
            {...props}
            hoveredBackground={Colors.DARK_BLUE_GRAY}
            style={{ backgroundColor: Colors.PRIMARY, border: `none` }}>

            <Small1 style={{ color: 'white' }}>{children}</Small1>
        </ButtonWithHover>
    );
};