import { CSSProperties, FC, useEffect, useState } from "react";
import { Card } from "../../Card";
import { Medium2 } from "../../Fonts/Medium2";
import { Small1 } from "../../Fonts/Small1";
import { Colors } from "../../../theme/Colors";
import { formatNumberAsFloat } from "../../../utils/numberUtils";

interface SummaryProps {
    data?: any
}

export const Summary: FC<SummaryProps> = ({ data }) => {
    // Services
    const [services, setServices] = useState<any>([]);
    const [servicesOneTimePrice, setServicesOneTimePrice] = useState<string>('--');
    const [serviceUsageCharge, setServiceUsageCharge] = useState<string>('--');

    // Day2Ops
    const [day2Ops, setDay2Ops] = useState<any>([]);
    const [day2OpsOneTimePrice, setDay2OpsOneTimePrice] = useState<string>('--');
    const [day2OpsUsageCharge, setday2OpsUsageCharge] = useState<string>('--');

    // Totals
    const [totalMonthlyCost, setTotalMonthlyCost] = useState<string>('--');
    const [totalOneTimePrice, setTotalOneTimePrice] = useState<string>('--');

    useEffect(() => {
        if (data) {

            setServices(data.serviceRequests);
            setDay2Ops(data.day2OpRequests);

            // Services
            var serviceTotalSetupAmount = 0;
            var serviceUsageCharge = 0;
            data.serviceRequests.forEach((service: any) => {
                serviceUsageCharge += service.quantity * service.monthlyCharge
                serviceTotalSetupAmount += service.setupAmount ? service.setupAmount : 0
            });
            setServicesOneTimePrice(formatNumberAsFloat(serviceTotalSetupAmount))
            setServiceUsageCharge(formatNumberAsFloat(serviceUsageCharge))

            // Day2Ops
            var day2OpsTotalSetupAmount = 0;
            var day2OpsUsageCharge = 0;
            data.day2OpRequests.forEach((day2Op: any) => {
                day2OpsUsageCharge += day2Op.monthlyCharge
                day2OpsTotalSetupAmount += day2Op.setupAmount ? day2Op.setupAmount : 0
            });
            setDay2OpsOneTimePrice(formatNumberAsFloat(day2OpsTotalSetupAmount))
            setday2OpsUsageCharge(formatNumberAsFloat(day2OpsUsageCharge))

            // Total Costs
            setTotalMonthlyCost(formatNumberAsFloat(data.estimatedTotalMonthlyCharge))
            setTotalOneTimePrice(formatNumberAsFloat(serviceTotalSetupAmount + day2OpsTotalSetupAmount))
        }
    }, [data]);


    const textSummaryStyle: CSSProperties = {
        font: 'Roboto',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#3B4758'
    }

    return (
        <Card style={{ padding: '0px' }}>
            {/* Header */}
            <div style={{
                height: '80px',
                borderRadius: '12px 12px 0px 0px',
                borderBottom: '0.5px solid #EEF0F4',
                padding: '30px 24px 30px 24px'
            }}>
                <Medium2>Summary</Medium2>
            </div>

            {/* Body */}

            {services.map((service: any, index: number) => (
                <div style={{
                    padding: '20px 24px 20px 24px',
                    borderTop: '0.5px solid #EEF0F4',
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'space-between'
                }}>
                    <Small1 style={{ whiteSpace: 'break-spaces' }}>{service.serviceName}</Small1>
                    <div>
                        <Small1 style={{ textAlign: 'right' }}>€ {service.quantity * service.monthlyCharge}</Small1>
                        <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>{service.quantity} x € {service.monthlyCharge}</Small1>
                    </div>
                </div>
            ))}

            <div style={{
                padding: '10px 24px 10px 24px',
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between'
            }}>
                <Small1 style={{ whiteSpace: 'break-spaces', color: Colors.PRE_APPROVED_GRAY }}>Usage Charge</Small1>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY, textAlign: 'end' }}>€ {serviceUsageCharge} / Month</Small1>
                </div>
            </div>

            <div style={{
                padding: '10px 24px 10px 24px',
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between'
            }}>
                <Small1 style={{ whiteSpace: 'break-spaces', color: Colors.PRE_APPROVED_GRAY }}>One time charges</Small1>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY, textAlign: 'right' }}>€ {servicesOneTimePrice}</Small1>
                </div>
            </div>

            {day2Ops.map((day2op: any, index: number) => (
                <div style={{
                    padding: '20px 24px 20px 24px',
                    borderTop: '0.5px solid #EEF0F4',
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'space-between'
                }}>
                    <Small1 style={{ whiteSpace: 'break-spaces' }}>{day2op.day2OpName}</Small1>
                    <div>
                        <Small1 style={{ textAlign: 'right' }}>€ {day2op.monthlyCharge}</Small1>
                        <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>1 x € {day2op.monthlyCharge}</Small1>
                    </div>
                </div>
            ))}

            <div style={{
                padding: '10px 24px 10px 24px',
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between'
            }}>
                <Small1 style={{ whiteSpace: 'break-spaces', color: Colors.PRE_APPROVED_GRAY }}>Usage Charges</Small1>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY, textAlign: 'end' }}>€ {day2OpsUsageCharge} / Month</Small1>
                </div>
            </div>

            <div style={{
                padding: '10px 24px 10px 24px',
                display: 'flex',
                gap: '20px',
                borderBottom: '0.5px solid #EEF0F4',
                justifyContent: 'space-between'
            }}>
                <Small1 style={{ whiteSpace: 'break-spaces', color: Colors.PRE_APPROVED_GRAY }}>One time charges</Small1>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY, textAlign: 'right' }}>€ {day2OpsOneTimePrice}</Small1>
                </div>
            </div>

            {/* Footer */}
            <div style={{
                padding: '20px 24px 5px 24px',
                height: '57px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <h3 style={textSummaryStyle}>Total monthly cost</h3>
                <h3 style={textSummaryStyle}>€ {totalMonthlyCost}</h3>
            </div>
            <div style={{
                padding: '5px 24px 30px 24px',
                height: '56px',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <h3 style={textSummaryStyle}>Total one time cost</h3>
                <h3 style={textSummaryStyle}>€ {totalOneTimePrice}</h3>
            </div>
        </Card>
    )
}

