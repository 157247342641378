import { CSSProperties, FC } from "react";
import { FontProps } from "../interfaces";
import { Colors } from "../../../theme/Colors";

export const Small1: FC<FontProps> = ({ children, ...props }) => {
    const styleH4: CSSProperties = {
        font: 'Inter',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16.94px',
        color: Colors.DARK_BLUE_GRAY,
        whiteSpace: 'nowrap'
    }

    return (
        <h4 style={{ ...styleH4, ...props.style }}>
            {children}
        </h4>
    )
}