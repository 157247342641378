import { useQuery } from "@apollo/client";
import { FC, useEffect, useMemo, useState } from "react";
import { CSSProperties } from "styled-components";
import { GET_SERVICE_CATEGORIES_QUERY } from "../../../graphql/catalog/queries";
import { Colors } from "../../../theme/Colors";
import { POLL_INTERVAL } from "../../../utils/constants";
import { ResetFilterButton } from "../../Button/ButtonFilters/ResetFilterButton";
import { Card } from "../../Card";
import { Large } from "../../Fonts/Large";
import { Medium2 } from "../../Fonts/Medium2";
import { Small1 } from "../../Fonts/Small1";
import { BreadcrumbItem } from "../../Header/Breadcrumb";
import { Loading } from "../../Loading";
import { SelectSortBy } from "../../SortBy/SelectSortBy";
import { ItemInCatalog, ItemInCatalogType } from "../ItemInCatalog";

interface ServicesCatalogProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const ServicesCatalog: FC<ServicesCatalogProps> = ({ changeBreadcrumb }) => {
    const [category, setCategory] = useState<string>();

    const [appliancesServices, setAppliancesServices] = useState<any>();
    const [computeServices, setComputeServices] = useState<any>();
    const [databaseServices, setDatabaseServices] = useState<any>();
    const [middlewareServices, setMiddlewareServices] = useState<any>();

    const breadcrumbItems = useMemo(() => [
        { label: 'Catalog', link: '/catalog/services' },
        { label: 'Services', link: '/catalog/services' }
    ], []);

    useEffect(() => {
        changeBreadcrumb(breadcrumbItems);
    }, [breadcrumbItems, changeBreadcrumb]);

    const { loading, error, data, refetch } =
        useQuery(GET_SERVICE_CATEGORIES_QUERY, {
            variables: {
                category: category
            },
            pollInterval: POLL_INTERVAL
        });
    
    
    useEffect(() => {
        if(data){            
            setAppliancesServices(data.getServiceCategories.filter((e: any) => e.category === "Appliances"))
            setComputeServices(data.getServiceCategories.filter((e: any) => e.category === "Compute"))
            setDatabaseServices(data.getServiceCategories.filter((e: any) => e.category === "Database"))
            setMiddlewareServices(data.getServiceCategories.filter((e: any) => e.category === "Middleware"))
        }
    }, [data])    

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '25% 25% 25% 25%',
        justifyContent: 'space-evenly',
        gap: '10px'
    }

    if (loading) return <Loading />

    return (
        <div style={{ margin: '10px' }}>
            <Card style={{ padding: '24px 30px 24px 30px', height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Large>Services</Large>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <SelectSortBy value={category} onChange={(e) => { setCategory(e.target.value) }}>
                        <option value="">Category</option>
                        <option value="Appliances">Appliances</option>
                        <option value="Compute">Compute</option>
                        <option value="Database">Database</option>
                        <option value="Middleware">Middleware</option>
                    </SelectSortBy>
                    <ResetFilterButton onClick={() => setCategory('')}>Reset Filters</ResetFilterButton>
                </div>
            </Card>

            <div style={{ padding: '15px 30px 15px 30px' }}>
                <Small1>Appliances {`(${appliancesServices ? appliancesServices.length : '--'})`}</Small1>
            </div>

            <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                {appliancesServices && appliancesServices.map((service: any) => {
                    return <ItemInCatalog data={service} type={ItemInCatalogType.SERVICE} refetch={refetch} />
                })}
            </div>

            <div style={{ padding: '15px 30px 15px 30px' }}>
                <Small1>Compute {`(${computeServices ? computeServices.length : '--'})`}</Small1>
            </div>

            <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                {computeServices && computeServices.map((service: any) => {
                    return <ItemInCatalog data={service} type={ItemInCatalogType.SERVICE} refetch={refetch} />
                })}
            </div>

            <div style={{ padding: '15px 30px 15px 30px' }}>
                <Small1>Databases {`(${databaseServices ? databaseServices.length : '--'})`}</Small1>
            </div>

            <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                {databaseServices && databaseServices.map((service: any) => {
                    return <ItemInCatalog data={service} type={ItemInCatalogType.SERVICE} refetch={refetch} />
                })}
            </div>

            <div style={{ padding: '15px 30px 15px 30px' }}>
                <Small1>Middleware {`(${middlewareServices ? middlewareServices.length : '--'})`}</Small1>
            </div>

            <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                {middlewareServices && middlewareServices.map((service: any) => {
                    return <ItemInCatalog data={service} type={ItemInCatalogType.SERVICE} refetch={refetch} />
                })}
            </div>

            {/* {error ? (<Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2>) : (
                data && data.getServiceCategories.map((e: any) => (
                    <>
                        <div style={{ padding: '15px 30px 15px 30px' }}>
                            <Small1>{e.name} {`(${e.items ? e.items.length : '--'})`}</Small1>
                        </div>

                        <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                            {e.items.map((item: any) => (
                                <ItemInCatalog data={item} type={ItemInCatalogType.SERVICE} refetch={refetch} />
                            ))}
                        </div>

                    </>
                ))
            )} */}
        </div>
    )
}