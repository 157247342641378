import React, { FC } from 'react';
import editIcon from '../../../svg/icons/Edit.svg';
import { IconButton } from '..';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

export const IconButtonModify : FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <IconButton {...props} icon={editIcon}></IconButton>
    )
}