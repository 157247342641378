import { FC } from "react";
import { Card } from "../../Card";
import { PreviousCartButton } from "../../Button/CartButton/PreviousCartButton";
import { CartButton } from "../../Button/CartButton";
import { Small1 } from "../../Fonts/Small1";
import { Colors } from "../../../theme/Colors";
import nextIcon from '../../../svg/icons/Next.svg';
import catalogIcon from '../../../svg/icons/Catalog.svg';


interface ThreeButtonsCheckOutBarProps {
    onClickBack: () => void
    onClickReview: () => void
}

export const ThreeButtonsCheckOutBar: FC<ThreeButtonsCheckOutBarProps> = ({ onClickBack, onClickReview }) => {
    return (
        <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', height: '90px'}}>
            <PreviousCartButton style={{ width: '140px' }} onClick={onClickBack}>Back</PreviousCartButton>

            <CartButton style={{ gap: '10px' }}>
                <Small1 style={{ color: Colors.WHITE }}>Go to Catalog</Small1>
                <img src={catalogIcon} alt="" />
            </CartButton>

            <CartButton onClick={onClickReview} style={{ gap: '10px' }}>
                <Small1 style={{ color: Colors.WHITE }}>Review</Small1>
                <img src={nextIcon} alt="" />
            </CartButton>
        </Card>
    )
}