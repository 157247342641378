import { useQuery } from "@apollo/client";
import { FC, useEffect, useMemo, useState } from "react";
import { CSSProperties } from "styled-components";
import { GET_DAY2_OP_CATEGORIES_QUERY } from "../../../graphql/catalog/queries";
import { POLL_INTERVAL } from "../../../utils/constants";
import { ResetFilterButton } from "../../Button/ButtonFilters/ResetFilterButton";
import { Card } from "../../Card";
import { Large } from "../../Fonts/Large";
import { Small1 } from "../../Fonts/Small1";
import { BreadcrumbItem } from "../../Header/Breadcrumb";
import { Loading } from "../../Loading";
import { SelectSortBy } from "../../SortBy/SelectSortBy";
import { ItemInCatalog, ItemInCatalogType } from "../ItemInCatalog";
import { Medium2 } from "../../Fonts/Medium2";
import { Colors } from "../../../theme/Colors";

interface Day2OperationsCatalogProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const Day2OperationsCatalog: FC<Day2OperationsCatalogProps> = ({ changeBreadcrumb }) => {
    const [itemLevelDay2ops, setItemLevelDay2ops] = useState<any>();
    const [serviceLevelDay2ops, setServiceLevelDay2ops] = useState<any>();
    const [category, setCategory] = useState<string>();

    const breadcrumbItems = useMemo(() => [
        { label: 'Catalog', link: '/catalog/day2ops' },
        { label: 'Day 2 Operations', link: '/catalog/day2ops' }
    ], []);

    useEffect(() => {
        changeBreadcrumb(breadcrumbItems);
    }, [breadcrumbItems, changeBreadcrumb]);

    const { loading, error, data, refetch } =
        useQuery(GET_DAY2_OP_CATEGORIES_QUERY, {
            variables: {
                category: category
            },
            pollInterval: POLL_INTERVAL
        });


    useEffect(() => {
        if (data) {
            setItemLevelDay2ops(data.getDay2OpCategories.filter((e: any) => e.kind === "ITEM"))
            setServiceLevelDay2ops(data.getDay2OpCategories.filter((e: any) => e.kind === "SERVICE"))
        }
    }, [data])

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '25% 25% 25% 25%',
        justifyContent: 'space-evenly',
        gap: '10px'
    }

    if (loading) return <Loading />

    return (
        <div style={{ margin: '10px' }}>
            <Card style={{ padding: '24px 30px 24px 30px', height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Large>Day 2 Operations</Large>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <SelectSortBy value={category} onChange={(e) => { setCategory(e.target.value) }}>
                        <option value="">Services</option>
                        <option value="Linux Server">Linux Server</option>
                        <option value="Windows Server">Windows Server</option>
                        <option value="Apache with JBoss Server">Apache with JBoss Server</option>
                        <option value="Apache with Tomcat Server">Apache with Tomcat Server</option>
                        <option value="Delphix appliance">Delphix appliance</option>
                        <option value="Deployment Manager">Deployment Manager</option>
                        <option value="IHS Server">IHS Server</option>
                        <option value="IIS System">IIS System</option>
                        <option value="JBoss Sytem">JBoss Sytem</option>
                        <option value="SAP on Linux Server">SAP on Linux Server</option>
                        <option value="SQL System">SQL System</option>
                        <option value="Tomcat System">Tomcat System</option>
                        <option value="WAS Cluster">WAS Cluster</option>
                    </SelectSortBy>
                    <ResetFilterButton onClick={() => { setCategory('') }}>Reset Filters</ResetFilterButton>
                </div>
            </Card>

            <div style={{ padding: '15px 30px 15px 30px' }}>
                <Small1>Item Level {`(${itemLevelDay2ops ? itemLevelDay2ops.length : '--'})`}</Small1>
            </div>

            <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                {itemLevelDay2ops && itemLevelDay2ops.map((day2op: any) => {
                    return <ItemInCatalog data={day2op} type={ItemInCatalogType.DAY_2_OP} refetch={refetch} />
                })}
            </div>

            <div style={{ padding: '15px 30px 15px 30px' }}>
                <Small1>Service Level {`(${serviceLevelDay2ops ? serviceLevelDay2ops.length : '--'})`}</Small1>
            </div>

            {error ? (<Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2>) : (
                <div style={{ ...gridContainer, marginLeft: '15px', marginRight: '15px' }}>
                    {serviceLevelDay2ops && serviceLevelDay2ops.map((day2op: any) => {
                        return <ItemInCatalog data={day2op} type={ItemInCatalogType.DAY_2_OP} refetch={refetch} />
                    })}
                </div>
            )}
        </div>
    )
}