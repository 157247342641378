import { CSSProperties, FC } from "react";
import reaload from '../../svg/icons/Reload.svg';
import done from '../../svg/icons/Done.svg';
import alert from '../../svg/icons/Alert.svg';
import close from '../../svg/icons/StatusClose.svg';
import { Small2 } from "../Fonts/Small2";
import { Colors } from "../../theme/Colors";

export enum StatusType {
    APPROVAL_IN_PROGRESS,
    OPEN,
    COMPLETED,
    CLOSED,
    PARTIALLY_FAILED,
    FAILED
}

interface StatusProps {
    status: StatusType
}

export const Status: FC<StatusProps> = ({ status }) => {
    const generalStyle: CSSProperties = {
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        gap: '3px',
        height: '20px',
        paddingLeft: '5px',
        paddingRight: '5px'
    }

    const textStyle: CSSProperties = {
        color: Colors.WHITE,
        fontWeight: '400',
        fontSize: '12px'
    }

    if (status === StatusType.APPROVAL_IN_PROGRESS) {
        return (
            <div style={{ backgroundColor: Colors.IN_PROGRESS_YELLOW, ...generalStyle }}>
                <img src={reaload} alt="" />
                <Small2 style={textStyle}>approval in progress</Small2>
            </div>
        )
    }

    if (status === StatusType.OPEN) {
        return (
            <div style={{ backgroundColor: Colors.COMPLETE_GREEN, ...generalStyle }}>
                <Small2 style={textStyle}>open</Small2>
            </div>
        )
    }

    if (status === StatusType.COMPLETED) {
        return (
            <div style={{ backgroundColor: Colors.COMPLETE_GREEN, ...generalStyle }}>
                <img  style={{ backgroundColor: Colors.WHITE, borderRadius: 'inherit'}} src={done} alt="" />
                <Small2 style={textStyle}>completed</Small2>
            </div>
        )
    }

    if (status === StatusType.CLOSED) {
        return (
            <div style={{ backgroundColor: Colors.DARK_BLUE_GRAY, ...generalStyle }}>
                <Small2 style={textStyle}>closed</Small2>
            </div>
        )
    }

    if (status === StatusType.PARTIALLY_FAILED) {
        return (
            <div style={{ backgroundColor: Colors.PARTIALLY_FAILED_ORANGE, ...generalStyle }}>
                <img  style={{ backgroundColor: Colors.DARK_BLUE_GRAY, borderRadius: 'inherit'}} src={alert} alt="" />
                <Small2 style={textStyle}>partially failed</Small2>
            </div>
        )
    }

    if (status === StatusType.FAILED) {
        return (
            <div style={{ backgroundColor: Colors.FAILED_RED, ...generalStyle }}>
                <img  style={{ backgroundColor: Colors.FAILED_RED, borderRadius: 'inherit'}} src={close} alt="" />
                <Small2 style={textStyle}>failed</Small2>
            </div>
        )
    }

    return (
        <></>
    )
}