import { Trans } from 'react-i18next';
import { FunctionComponent } from 'react';

interface TextProps {
  text: string;
  className?: string;
  skipTranslation?: boolean;
  translationArgs?: any;
  style?: any;
}

export const Text: FunctionComponent<TextProps> = ({
  text,
  className,
  skipTranslation,
  translationArgs,
  style,
}) => {
  return (
    <span style={style} className={className}>
      {skipTranslation ? (
        text
      ) : (
        <Trans
          i18nKey={text}
          values={translationArgs}
          components={[<b key={0} />, <b key={1} className="weight400" />]}
        />
      )}
    </span>
  );
};
