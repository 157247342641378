import { FC, useEffect, useState } from "react";
import { PreviousButton } from "../Buttons/PreviousButton";
import { NextButton } from "../Buttons/NextButton";
import { PaginationButton } from "../Buttons";
import { Small2 } from "../../Fonts/Small2";
import { Colors } from "../../../theme/Colors";

interface PaginationProps {
    setOffset: (offset: number) => void;
    offset: number;
    limit: number;
    totalCount: number;
}

export const PaginationGroup: FC<PaginationProps> = ({ offset, limit, totalCount, setOffset }) => {
    const [isDisabledPrevious, setIsDisabledPrevious] = useState<boolean>(false);
    const [isDisabledNext, setIsDisabledNext] = useState<boolean>(false);

    useEffect(() => {
        if (offset === 0) {
            setIsDisabledPrevious(true)
        } else {
            setIsDisabledPrevious(false)
        }

        if (offset === Math.ceil(totalCount / limit) - 1) {
            setIsDisabledNext(true)
        } else {
            setIsDisabledNext(false)
        }
    }, [offset, limit, totalCount]);

    return (
        <div style={{ display: 'flex', height: '32px', width: '284px', justifyContent: 'space-evenly' }} className="pagination-group">
            <PreviousButton disabled={isDisabledPrevious} onClick={() => isDisabledPrevious ? {} : setOffset(offset - 1)} />
            <PaginationButton style={{ backgroundColor: Colors.PRE_APPROVED_GRAY }}>
                <Small2 style={{ color: Colors.WHITE }}>{offset + 1}</Small2>
            </PaginationButton>
            <PaginationButton onClick={() => setOffset(offset + 1)}>
                <Small2 style={{ color: Colors.PRE_APPROVED_GRAY }}>{offset + 2}</Small2>
            </PaginationButton>
            <PaginationButton onClick={() => setOffset(offset + 2)}>
                <Small2 style={{ color: Colors.PRE_APPROVED_GRAY }}>{offset + 3}</Small2>
            </PaginationButton>
            <PaginationButton>
                <Small2 style={{ color: Colors.PRE_APPROVED_GRAY }}>...</Small2>
            </PaginationButton>
            <PaginationButton onClick={() => setOffset(Math.ceil(totalCount / limit) - 1)}>
                <Small2 style={{ color: Colors.PRE_APPROVED_GRAY }}>{Math.ceil(totalCount / limit)}</Small2>
            </PaginationButton>
            <NextButton disabled={isDisabledNext} onClick={() => isDisabledNext ? {} : setOffset(offset + 1)} />
        </div>
    )
}