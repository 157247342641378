import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { utilsSelector } from '../redux/utils/selectors';
import { CSSProperties } from 'styled-components';
import { Colors } from '../theme/Colors';

export const Loading: FunctionComponent = () => {
  const style: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    top: 0,
    left: 0,
    overflowY: 'hidden'
  }

  const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  const spinnerInnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.3)',
    borderTop: `4px solid ${Colors.WHITE}`,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    animation: 'spin 1s linear infinite',
  };

  const keyframesStyle = {
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
  };

  return (
    <div style={style}>
      <div style={spinnerStyle}>
        <div style={spinnerInnerStyle} />
        <style>
          {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
        </style>
      </div>
    </div>
  )
};
