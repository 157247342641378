import { createReducer } from '@reduxjs/toolkit';
import { setApps, setCurrentApp } from './actions';

export interface AppsState {
  apps: string[];
  currentApp: string;
}

const defaultState: AppsState = {
  apps: [],
  currentApp: '',
};

export const appsReducer = createReducer<AppsState>(defaultState, {
  [setApps.type]: (state, action): AppsState => ({
    ...state,
    apps: action.payload,
  }),
  [setCurrentApp.type]: (state, action): AppsState => ({
    ...state,
    currentApp: action.payload,
  }),
});
