import { FC } from "react";
import { CSSProperties } from "styled-components";
import { Colors } from "../../../../theme/Colors";
import { Small1 } from "../../../Fonts/Small1";
import { Select } from "../../../Inputs/Select";
import { Small2 } from "../../../Fonts/Small2";
import { IconButtonModify } from "../../../IconButtons/Modify";
import { IconButtonDelete } from "../../../IconButtons/Delete";

interface ServiceRowProps {
    serviceData: any;
    onClickModify: (id: string) => void;
    onClickDelete: (id: string) => void;
}

export const ServiceRow: FC<ServiceRowProps> = ({ serviceData, onClickModify, onClickDelete }) => {
    const quantityOptions = Array.from({ length: 100 }, (_, index) => index + 1);

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '18% 20% 15% 20% 15% 12%',
        gap: '10px',
        alignItems: 'center'
    }

    return (
        <div style={{
            height: '100px',
            padding: '30px 24px 30px 24px',
            borderTop: `0.5px solid #EEF0F4`,
            ...gridContainer
        }}>
            <Small1>{serviceData.serviceName}</Small1>
            <Small2 style={{ overflow: 'hidden'}}>{serviceData.serviceInstancePrefixName}</Small2>
            <Small2>€ {serviceData.monthlyCharge}</Small2>
            <Select style={{ width: '90px'}} value={serviceData.quantity}>
                {quantityOptions.map((quantity) => (
                    <option key={quantity} value={quantity.toString()}>
                        <Small2>{quantity}</Small2>
                    </option>
                ))}
            </Select>
            <Small2>
                € {serviceData.monthlyCharge}/Month <br /> {serviceData.setupAmount ? ` + € ${serviceData.setupAmount} /Setup` : ''}
            </Small2>
            <div style={{ display: 'flex'}}>
                <IconButtonModify onClick={() => onClickModify(serviceData.id)}/>
                <IconButtonDelete onClick={() => onClickDelete(serviceData.id)}/>
            </div>
        </div>
    )
}