import { FC, useState } from "react";
import { ButtonProps, ButtonWithHover } from "..";
import { Colors } from "../../../theme/Colors";
import { Small1 } from "../../Fonts/Small1";

export const ButtonPrimaryOutline: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {
    const [hovered, setHovered] = useState<boolean>(false)

    return (
        <ButtonWithHover
            {...props}
            hoveredBackground={Colors.PRIMARY}
            hoveredColor={Colors.WHITE}
            style={{ color: Colors.PRIMARY, backgroundColor: 'white', border: `1px solid ${Colors.PRIMARY}` }}>
            <Small1 style={{ color: "inherit" }}>{children}</Small1>
        </ButtonWithHover>
    );
};