
import { useQuery } from "@apollo/client";
import { CSSProperties, FC } from "react";
import { ButtonPrimaryFilled } from "../../Button/ButtonPrimaryFilled";
import { Card } from "../../Card";
import { Colors } from "../../../theme/Colors";
import { Large } from "../../Fonts/Large";
import { Small1 } from "../../Fonts/Small1";
import { CartRow } from "./CartRow";
import { GET_LAST_FIVE_CARTS } from "../../../graphql/cart/queries";
import { Medium2 } from "../../Fonts/Medium2";
import { Loading } from "../Loading";
import { POLL_INTERVAL } from "../../../utils/constants";

interface CartsProps {
    viewAllOnClick: () => void;
}

export const Carts: FC<CartsProps> = ({ viewAllOnClick }) => {

    const { loading, error, data } =
        useQuery(GET_LAST_FIVE_CARTS, {
            pollInterval: POLL_INTERVAL
        });

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(5, 1fr)',
        gridTemplateColumns: 'repeat(4, 1fr) 5%',
        gap: '10px'
    }


    return (
        <Card style={{ padding: "24px 32px", height: "356px", opacity: loading ? '0.5' : '1' }}>

            {/* Commands */}
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "32px" }}>
                <Large>Open Carts</Large>
                <ButtonPrimaryFilled onClick={viewAllOnClick}>
                    View All
                </ButtonPrimaryFilled>
            </div>

            {/* Table Heading */}
            <div style={{ ...gridContainer, height: "30px" }}>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Cart Name</Small1>
                </div>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Purpose</Small1>
                </div>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Estimated Cost</Small1>
                </div>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Last Updated On</Small1>
                </div>
                <div>
                    {/* Empty Slot */}
                </div>
            </div>

            {/* Table Rows */}
            {error ? <Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2> : <></>}
            {loading ? <Loading /> : <></>}
            {data ? data.getLastFiveCarts.map((r: any) => (
                <CartRow cartData={r} />
            )) : <></>}

        </Card>
    )
}