import { FC } from "react";
import { CSSProperties } from "styled-components";
import { Small1 } from "../../../Fonts/Small1";
import { Small2 } from "../../../Fonts/Small2";
import { Small3 } from "../../../Fonts/Small3";


interface ServiceRowProps {
    serviceData: any;
    position: number;
}

export const ServiceRow: FC<ServiceRowProps> = ({ serviceData, position }) => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '5% 25% 20% 10% 20% 15%',
        gap: '10px',
        alignItems: 'center'
    }

    return (
        <>
            <div style={{
                height: '50px',
                padding: '30px 24px 30px 24px',
                borderTop: `0.5px solid #EEF0F4`,
                ...gridContainer
            }}>
                <Small1>{position}</Small1>
                <Small1 style={{ overflow: 'hidden' }}>{serviceData.serviceName}</Small1>
                <Small2 style={{ overflow: 'hidden' }}>{serviceData.serviceInstancePrefixName}</Small2>
                <Small2>€ {serviceData.monthlyCharge}</Small2>
                <Small2>{serviceData.quantity}</Small2>
                <Small2 style={{ textAlign: 'right' }}>
                    € {serviceData.monthlyCharge}/Month <br /> {serviceData.setupAmount ? ` + € ${serviceData.setupAmount} /Setup` : ''}
                </Small2>
            </div>
            <div style={{
                padding: '10px 24px 38px 24px',
                ...gridContainer
            }}>
                <Small1></Small1>
                <Small3 style={{ color: '#3B4758' }}>
                    {serviceData.payload.items.length === 0 ? (
                        <p>--</p>
                    ) : (
                        serviceData.payload.items.map((item: any, index: any) => (
                            <>{item.label}: {item.value} <br /></>
                        ))
                    )}
                </Small3>
                <Small2></Small2>
                <Small2></Small2>
                <Small2></Small2>
                <Small2></Small2>
            </div>
        </>
    )
}