import { FC } from "react";
import { CardContainer } from "../CardContainer";
import { StepIcon } from "../StepIcon";
import { Medium1 } from "../../Fonts/Medium1";
import { Colors } from "../../../theme/Colors";

export enum StateCartSteps {
    FIRST,
    SECOND,
    THIRD
}

interface CartStepsProps extends React.HTMLAttributes<HTMLDivElement> {
    state: StateCartSteps
}

export const CartSteps: FC<CartStepsProps> = ({ state }) => {
    return (
        <CardContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StepIcon isActive={state === StateCartSteps.FIRST}>1</StepIcon>
                <Medium1 style={{
                    color: state === StateCartSteps.FIRST ? Colors.DARK_BLUE_GRAY : Colors.PRE_APPROVED_GRAY,
                    paddingLeft: '8px'
                }}>Main Parameters of the Cart</Medium1>
            </div>
            <hr style={{ width: '5%' }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StepIcon isActive={state === StateCartSteps.SECOND}>2</StepIcon>
                <Medium1 style={{
                    color: state === StateCartSteps.SECOND ? Colors.DARK_BLUE_GRAY : Colors.PRE_APPROVED_GRAY,
                    paddingLeft: '8px'
                }}>Services and Day 2 operations</Medium1>
            </div>
            <hr style={{ width: '5%' }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StepIcon isActive={state === StateCartSteps.THIRD}>3</StepIcon>
                <Medium1 style={{
                    color: state === StateCartSteps.THIRD ? Colors.DARK_BLUE_GRAY : Colors.PRE_APPROVED_GRAY,
                    paddingLeft: '8px'
                }}>Review</Medium1>
            </div>
        </CardContainer>
    )
}