import { CSSProperties, FC, useState } from "react";
import { Button, ButtonProps } from "..";
import PlusIcon from '../../../svg/icons/Plus.svg';
import { Colors } from "../../../theme/Colors";
import { Medium2 } from "../../Fonts/Medium2";


export const AddCartButton: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {
    const [hovered, setHovered] = useState<boolean>(false)

    const buttonStyle: CSSProperties = {
        backgroundColor: hovered ? Colors.DARK_BLUE_GRAY : Colors.PRIMARY,
        color: '#fff',
        transition: "background-color 0.3s ease-in-out",
        height: '198px',
        width: '100%',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    return (
        <Button onMouseLeave={() => setHovered(false)} onMouseEnter={() => setHovered(true)} style={{ ...buttonStyle, ...props.style }} >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img style={{width: '45px', height: '44.77px'}} src={PlusIcon} alt="" />
                <Medium2 style={{ color: Colors.WHITE, marginTop: '5px' }}>New Cart</Medium2>
            </div>
        </Button>
    );
};