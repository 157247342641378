import { CSSProperties, FC, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import cartLogo from '../../svg/icons/SideBar/cart/logo.svg';
import day2OpIcon from '../../svg/icons/SideBar/catalog/day2op-icon.svg';
import servicesIcon from '../../svg/icons/SideBar/catalog/services-icon.svg';
import cmpLogo from '../../svg/icons/SideBar/cmp-logo.svg';
import inventoryLogo from '../../svg/icons/SideBar/inventory/logo.svg';
import linkClosed from '../../svg/icons/SideBar/link-closed.svg';
import linkOpen from '../../svg/icons/SideBar/link-open.svg';
import allOrderLogo from '../../svg/icons/SideBar/order/all-logo.svg';
import toApproveOrderLogo from '../../svg/icons/SideBar/order/to-approve-logo.svg';
import styles from './SideBar.module.css';
import { Small2 } from "../Fonts/Small2";

interface HeaderProps {
    collapsed: boolean
    style?: CSSProperties
}

export const SideBar: FC<HeaderProps> = ({ collapsed, style }) => {
    const [isCartOpen, setIsCartOpen] = useState<boolean>(false);
    const [isOrderToApproveOpen, setIsOrderToApproveOpen] = useState<boolean>(false);
    const [isOrderAllOpen, setIsOrderAllOpen] = useState<boolean>(false);
    const [isCatalogServicesOpen, setIsCatalogServicesOpen] = useState<boolean>(false);
    const [isCatalogDay2OpOpen, setIsCatalogDay2OpOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };

    const toggleOrderToApprove = () => {
        setIsOrderToApproveOpen(!isOrderToApproveOpen);
    }

    const toggleOrderAll = () => {
        setIsOrderAllOpen(!isOrderAllOpen);
    }

    const toggleCatalogServices = () => {
        setIsCatalogServicesOpen(!isCatalogServicesOpen);
    }

    const toggleCatalogDay2Op = () => {
        setIsCatalogDay2OpOpen(!isCatalogDay2OpOpen);
    }

    const triggerHome = () => {
        navigate('/dashboard');
    }

    if (collapsed) {
        return <></>
    }

    return (
        <div style={style} className={styles['side-bar']}>
            <div onClick={triggerHome} className={styles["image-container"]}>
                <img src={cmpLogo} alt="Logo" />
            </div>
            <hr />

            {/* --- CARTS --- */}
            <div className={styles['links-container']}>
                <label>Carts</label>
                <div className={styles['link']}>
                    <img onClick={toggleCart} src={isCartOpen ? linkOpen : linkClosed} alt="Cart" />
                    <img src={cartLogo} alt="" />
                    <Small2 onClick={() => navigate('')}>All Carts</Small2>
                </div>
                {isCartOpen && (
                    <div className={styles['options']}>
                        <Link to={"/open-carts"}>Open Carts</Link>
                        <Link to={"/closed-carts"}>Closed Carts</Link>
                    </div>
                )}
            </div>

            {/* --- ORDERS --- */}
            <div className={styles['links-container']}>
                <label>Orders</label>
                <div className={styles['link']}>
                    <img onClick={toggleOrderToApprove} src={isOrderToApproveOpen ? linkOpen : linkClosed} alt="Cart" />
                    <img src={toApproveOrderLogo} alt="" />
                    <Small2 onClick={() => navigate('all-orders')}>To Approve</Small2>
                </div>
                {isOrderToApproveOpen && (
                    <div className={styles['options']}>
                        <Link to="/technical-approve">Technical approve</Link>
                        <Link to="/financial-approve">Financial approve</Link>
                        <Link to="/vts-approve">VTS approve</Link>
                    </div>
                )}

                <div className={styles['link']}>
                    <img onClick={toggleOrderAll} src={isOrderAllOpen ? linkOpen : linkClosed} alt="Cart" />
                    <img src={allOrderLogo} alt="" />
                    <Small2 onClick={() => navigate('all-orders')}>All Orders</Small2>
                </div>
                {isOrderAllOpen && (
                    <div className={styles['options']}>
                        <Link to="/completed">Completed</Link>
                        <Link to="/in-progress">In Progress</Link>
                        <Link to="/rejected">Rejected</Link>
                        <Link to="/failed">Failed</Link>
                    </div>
                )}
            </div>

            {/* --- CATALOG --- */}
            <div className={styles['links-container']}>
                <label>Catalog</label>
                <div className={styles['link']}>
                    <img onClick={toggleCatalogServices} src={isCatalogServicesOpen ? linkOpen : linkClosed} alt="Cart" />
                    <img src={servicesIcon} alt="" />
                    <Small2 onClick={() => navigate('/catalog/services')}>Services</Small2>
                </div>
                {isCatalogServicesOpen && (
                    <div className={styles['options']}>
                        <Link to="/appliances">Appliances</Link>
                        <Link to="/compute">Compute</Link>
                        <Link to="/databases">Databases</Link>
                        <Link to="/middleware">Middleware</Link>
                    </div>
                )}

                <div className={styles['link']}>
                    <img onClick={toggleCatalogDay2Op} src={isCatalogDay2OpOpen ? linkOpen : linkClosed} alt="Cart" />
                    <img src={day2OpIcon} alt="" />
                    <Small2 onClick={() => navigate('/catalog/day2ops')}>Day 2 operations</Small2>
                </div>
                {isCatalogDay2OpOpen && (
                    <div className={styles['options']}>
                        <Link to="/item-level">Item Level</Link>
                        <Link to="/service-level">Service Level</Link>
                    </div>
                )}
            </div>

            {/* --- INVENTORY --- */}
            <div className={styles['links-container']}>
                <label>Inventory</label>
                <div style={{ marginLeft: '22px' }} className={styles['link']}>
                    <img src={inventoryLogo} alt="" />
                    <Link to="/open-carts">Orderd Services</Link>
                </div>
            </div>
        </div>
    );
}