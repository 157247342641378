import { CSSProperties } from "styled-components"

export const toastRootstyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: 0,
    right: 0,
    overflowY: 'hidden',
    margin: '10px',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.1)'
}

export const toastStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
}