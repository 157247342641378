import { CSSProperties, FC } from "react";
import { ButtonPrimaryOutline } from "../../../Button/ButtonPrimaryOutline";
import { Small1 } from "../../../Fonts/Small1";

interface ServiceRowProps {
    serviceData: any
}

export const ServiceRow: FC<ServiceRowProps> = ({ serviceData }) => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(4, 1fr)',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '10px'
    }

    const colStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center'
    }

    return (
        <div style={{ ...gridContainer, height: "40px", borderTop: `1px solid #EEF0F4`, paddingTop: '5px' }}>
            <div style={colStyle}>
                <Small1>{serviceData.name}</Small1>
            </div>
            <div style={colStyle}>
                <p>
                    {/* {serviceData.surname} */}TTT
                </p>
            </div>
            <div style={colStyle}>
                <Small1>{serviceData.itemsNumber}</Small1>
            </div>
            <div style={colStyle}>
                <div style={{ display: "flex", flexGrow: "1", justifyContent: "end" }}>
                    <ButtonPrimaryOutline>Open</ButtonPrimaryOutline>
                </div>
            </div>
        </div>
    )
}