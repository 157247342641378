import styled from 'styled-components';
import { FunctionComponent, useEffect, useState } from 'react';
import * as React from 'react';
import { Color } from '../theme';

interface SvgIconProps {
  color?: Color | string;
  pointer?: boolean;
  svg: JSX.Element;
  svgHover?: JSX.Element;
  rotateDeg?: number;
  style?: React.CSSProperties;
  center?: boolean;
  className?: string;
  onClick?: () => void;
}

export const SvgIcon: FunctionComponent<SvgIconProps> = ({
  svg,
  svgHover,
  ...props
}) => {
  const [svgValue, setSvgValue] = useState(svg);
  useEffect(() => {
    if (svgValue !== svg) setSvgValue(svg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svg]);

  return (
    <SvgIconContainer
      onMouseOver={() => svgHover && setSvgValue(svgHover)}
      onMouseLeave={() => svgHover && setSvgValue(svg)}
      {...props}
    >
      {svgValue}
    </SvgIconContainer>
  );
};

type SvgIconContainerProps = Omit<SvgIconProps, 'svg'>;

const SvgIconContainer = styled.div<SvgIconContainerProps>`
  display: flex;
  transform: rotate(
    ${({ rotateDeg }) => (rotateDeg ? `${rotateDeg}deg` : '0')}
  );

  ${({ center }) => center && 'align-items: center; justify-content: center;'}

  &:hover {
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};
  }

  svg {
    fill: ${({ color }) => color};

    path {
      fill: ${({ color }) => color};
    }
  }
`;
