import { FC } from "react";
import { CSSProperties } from "styled-components";
import { Small1 } from "../../../Fonts/Small1";
import { Small2 } from "../../../Fonts/Small2";
import { Small3 } from "../../../Fonts/Small3";


interface Day2OpRowProps {
    day2OpData: any;
    position: number;
}

export const Day2OpRow: FC<Day2OpRowProps> = ({ day2OpData, position }) => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '5% 25% 20% 10% 20% 15%',
        gap: '10px',
        alignItems: 'center'
    }

    return (
        <>
            <div style={{
                height: '50px',
                padding: '30px 24px 30px 24px',
                borderTop: `0.5px solid #EEF0F4`,
                ...gridContainer
            }}>
                <Small1>{position}</Small1>
                <Small1 style={{ overflow: 'hidden' }}>{day2OpData.day2OpName}</Small1>
                <Small2 style={{ overflow: 'hidden' }}>{day2OpData.serviceInstanceName}</Small2>
                <Small2>{day2OpData.itemName}</Small2>
                <Small2>{day2OpData.applicationCode}</Small2>
                <Small2 style={{ textAlign: 'right' }}>
                    € {day2OpData.monthlyCharge}/Month <br /> {day2OpData.setupAmount ? ` + € ${day2OpData.setupAmount} /Setup` : ''}
                </Small2>
            </div>
            <div style={{
                padding: '10px 24px 24px 24px',
                ...gridContainer
            }}>
                <Small1></Small1>
                <Small3 style={{ color: '#3B4758' }}>
                    {day2OpData.payload.items.length === 0 ? (
                        <p>--</p>
                    ) : (
                        day2OpData.payload.items.map((item: any, index: any) => (
                            <>{item.label}: {item.value} <br /></>
                        ))
                    )}
                </Small3>
                <Small2></Small2>
                <Small2></Small2>
                <Small2></Small2>
                <Small2></Small2>
            </div>
        </>
    )
}