import { CSSProperties, FC, useState } from "react";
import { Colors } from "../../../theme/Colors";
import { Small3 } from "../../Fonts/Small3";

interface SubMenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> { }


export const SubMenuButton: FC<SubMenuButtonProps> = ({ children, ...props }) => {
    const [hovered, setHovered] = useState<boolean>(false)

    const style: CSSProperties = {
        width: '120px',
        height: '40px',
        textAlign: 'start',
        padding: '10px',
        backgroundColor: hovered ? Colors.WHITE : Colors.DARK_BLUE_GRAY
    }

    return (
        <button
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{ ...style, ...props.style }}>
            <Small3 style={{ color: hovered ? Colors.DARK_BLUE_GRAY : Colors.WHITE }}>{children}</Small3>
        </button>
    )
}