import { CSSProperties, FC } from "react";
import { FontProps } from "../interfaces";
import { Colors } from "../../../theme/Colors";

export const Large: FC<FontProps> = ({ children, ...props }) => {
    const styleH1: CSSProperties = {
        font: 'Inter',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '-2%',
        color: Colors.DARK_BLUE_GRAY
    }

    return (
        <h1 style={{ ...styleH1, ...props.style }}>
            {children}
        </h1>
    )
}