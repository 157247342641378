import { KeycloakService } from '../services/KeycloakService';

export const getDateHourAsString = (epochMillis: number) => {
  const locale =
    KeycloakService.getLocale() === 'en' ||
      KeycloakService.getLocale() === 'en-EN'
      ? 'en-GB'
      : KeycloakService.getLocale();
  const date = new Date(epochMillis);
  let hours = date.getHours().toString();
  if (hours.length === 1) hours = '0' + hours;
  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = '0' + minutes;
  return `${date.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} ${hours}:${minutes}`;
};

export const getDateAsString = (epochMillis: number) => {
  const locale =
    KeycloakService.getLocale() === 'en' ||
      KeycloakService.getLocale() === 'en-EN'
      ? 'en-GB'
      : KeycloakService.getLocale();
  return new Date(epochMillis).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getHoursAndMinutesAsString = (epochMillis: number) => {
  const date = new Date(epochMillis);
  let hours = date.getHours().toString();
  if (hours.length === 1) hours = '0' + hours;
  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = '0' + minutes;
  return `${hours}:${minutes}`;
};

export const isToday = (epocMillis: number) => {
  return getDateAsString(Date.now()) === getDateAsString(epocMillis);
};

export const isYesterday = (epocMillis: number) => {
  let yesterday = new Date();
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(yesterday.getDate() - 1);
  return getDateAsString(yesterday.getTime()) === getDateAsString(epocMillis);
};

export const convertDateToStringMonthLetter = (timestamp: number) => {
  const date = new Date(timestamp);
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Format the date
  return`${month} ${day}, ${year}`;
}

export function timestampToDateString(timestamp: number): string {
  const date = new Date(timestamp); // Moltiplica per 1000 per convertire i secondi in millisecondi

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi sono 0-based, quindi aggiungi 1 e completa con '0' se necessario
  const day = String(date.getDate()).padStart(2, '0');

  return `${month}/${day}/${year}`;
}

