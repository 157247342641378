import { gql } from '@apollo/client/core';

export const TOGGLE_FAVOURITE_SERVICE_MUTATION = gql`
  mutation ToggleFavouriteService(
    $serviceName: String!
  ) {
    toggleFavouriteService(serviceName: $serviceName) {
      _
    }
  }
`;

export const TOGGLE_FAVOURITE_DAY2OP_MUTATION = gql`
  mutation ToggleFavouriteDay2Op(
    $day2OpName: String!
  ) {
    toggleFavouriteDay2Op(day2OpName: $day2OpName) {
      _
    }
  }
`;