import { CSSProperties, FC } from "react";
import { FontProps } from "../interfaces";
import { Colors } from "../../../theme/Colors";

export const Small3: FC<FontProps> = ({ children, ...props }) => {
    const styleH6: CSSProperties = {
        font: 'Inter',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '20px',
        color: Colors.DARK_BLUE_GRAY
    }

    return (
        <h6 style={{ ...styleH6, ...props.style }}>
            {children}
        </h6>
    )
}