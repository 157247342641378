import { createAction } from '@reduxjs/toolkit';

enum Actions {
  SET_APPS = 'SET_APPS',
  SET_CURRENT_APP = 'SET_CURRENT_APP',
}

export const setApps = createAction<string[]>(Actions.SET_APPS);

export const setCurrentApp = createAction<string>(Actions.SET_CURRENT_APP);
