import { FC } from "react";
import { Card } from "../../Card";
import { PreviousCartButton } from "../../Button/CartButton/PreviousCartButton";
import { CartButton } from "../../Button/CartButton";
import { Small1 } from "../../Fonts/Small1";
import { Colors } from "../../../theme/Colors";
import nextIcon from '../../../svg/icons/Next.svg';
import catalogIcon from '../../../svg/icons/Catalog.svg';


interface DefaultCheckOutBarProps {
    onClickBack: () => void
    onClickNext: () => void
}

export const DefaultCheckOutBar: FC<DefaultCheckOutBarProps> = ({ onClickBack, onClickNext }) => {
    return (
        <Card style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', height: '90px'}}>
            <PreviousCartButton style={{ width: '140px' }} onClick={onClickBack}>Back</PreviousCartButton>

            <CartButton onClick={onClickNext} style={{ gap: '10px' }}>
                <Small1 style={{ color: Colors.WHITE }}>Create Order</Small1>
                <img src={nextIcon} alt="" />
            </CartButton>
        </Card>
    )
}