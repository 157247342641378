import { CSSProperties, FC, useState } from "react";
import { Small1 } from "../../../Fonts/Small1";
import { IconButtonDots } from "../../../IconButtons/Dots"
import { getDateAsString } from "../../../../utils/dateUtils";
import { SubMenu } from "../../../SubMenu";

interface CartRowProps {
    cartData: any
}

export const CartRow: FC<CartRowProps> = ({ cartData }) => {
    const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(5, 1fr)',
        gridTemplateColumns: 'repeat(4, 1fr) 5%',
        gap: '10px'
    }


    const colStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center'
    }

    return (
        <div style={{ ...gridContainer, height: "40px", borderTop: `1px solid #EEF0F4`, paddingTop: '5px' }}>
            <div style={colStyle}>
                <Small1>{cartData.name}</Small1>
            </div>
            <div style={colStyle}>
                <Small1>{cartData.description}</Small1>
            </div>
            <div style={colStyle}>
                <Small1>{`€${cartData.lastEstimatedCost}`}</Small1>
            </div>
            <div style={colStyle}>
                <Small1>{getDateAsString(cartData.lastUpdate)}</Small1>
            </div>
            <div style={colStyle}>
                <div style={{ display: "flex", flexGrow: "1", justifyContent: "end" }}>
                    <IconButtonDots
                        onMouseEnter={() => setOpenSubMenu(true)}
                        onMouseLeave={() => setOpenSubMenu(false)}></IconButtonDots>
                    {openSubMenu ?
                        <SubMenu
                            onMouseEnter={() => setOpenSubMenu(true)}
                            onMouseLeave={() => setOpenSubMenu(false)}></SubMenu> : <></>}
                </div>
            </div>
        </div>
    )
}