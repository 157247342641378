import { FunctionComponent } from 'react';
import { SvgIcon } from '../SvgIcon';
import { Ko, Ok } from '../../svg';
import { Text } from '../Text';

export interface NotificationObjNoId {
  title: string;
  text?: string;
  ok: boolean;
  skipTranslation?: boolean;
}

export interface NotificationObj extends NotificationObjNoId {
  id: string;
}

interface NotificationProps extends NotificationObj {
  index: number;
}

export const Notification: FunctionComponent<NotificationProps> = ({
  title,
  skipTranslation,
  index,
  text,
  ok,
}) => {
  return (
    <div
      className={`flex items-start p-4 gap-4 bg-white rounded-lg animate-animated animate-slideInLeft ${
        index > 0 && 'mb-4'
      }`}
      style={{
        boxShadow: '0px 3px 16px -4px rgba(0, 0, 0, 0.21)',
      }}
    >
      <div className="flex align-top">
        <SvgIcon svg={ok ? <Ok /> : <Ko />} />
      </div>
      <div className="flex flex-col">
        {title && (
          <Text
            text={title}
            skipTranslation={skipTranslation}
            className="font-semibold"
            style={{
              fontSize: '1.25rem',
              lineHeight: '1.75rem',
            }}
          />
        )}
        {text && (
          <Text
            text={text}
            skipTranslation={skipTranslation}
            className="font-normal"
            style={{
              fontSize: '1rem',
              lineHeight: '1.5rem',
            }}
          />
        )}
      </div>
    </div>
  );
};
