
import { CSSProperties, FC, useEffect, useState } from "react";
import { Card } from "../../Card";
import { Small1 } from "../../Fonts/Small1";
import { ButtonPrimaryFilled } from "../../Button/ButtonPrimaryFilled";
import { Large } from "../../Fonts/Large";
import { Colors } from "../../../theme/Colors";
import { ServiceRow } from "./ServiceRow";
import { useQuery } from "@apollo/client";
import { GET_SERVICE_CATEGORIES_QUERY } from "../../../graphql/catalog/queries";
import { Loading } from "../Loading";
import { ButtonPrimaryOutline } from "../../Button/ButtonPrimaryOutline";
import { Medium2 } from "../../Fonts/Medium2";
import { POLL_INTERVAL } from "../../../utils/constants";

export const Services: FC = () => {
    const [servicesRows, setServicesRows] = useState<any>();

    const { loading, error, data } =
        useQuery(GET_SERVICE_CATEGORIES_QUERY, {
            pollInterval: POLL_INTERVAL
        });

    useEffect(() => {
        if(data){
            setServicesRows([
                {
                    name: 'Appliances',
                    itemsNumber: data.getServiceCategories.filter((e: any) => e.category === "Appliances").length
                },
                {
                    name: 'Compute',
                    itemsNumber: data.getServiceCategories.filter((e: any) => e.category === "Compute").length
                },
                {
                    name: 'Database',
                    itemsNumber: data.getServiceCategories.filter((e: any) => e.category === "Database").length
                },
                {
                    name: 'Middleware',
                    itemsNumber: data.getServiceCategories.filter((e: any) => e.category === "Middleware").length
                },
            ])
        }
    }, [data])    

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(4, 1fr)',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '10px'
    }

    const colStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center'
    }

    return (
        <Card style={{ padding: "24px 32px", height: "356px", opacity: loading ? '0.5' : '1' }}>

            {/* Commands */}
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "32px" }}>
                <Large>Services</Large>
                <ButtonPrimaryFilled>
                    View All
                </ButtonPrimaryFilled>
            </div>

            {/* Table Heading */}
            <div style={{ ...gridContainer, height: "30px" }}>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Category</Small1>
                </div>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Services</Small1>
                </div>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Items</Small1>
                </div>
                <div>
                    {/* Empty Slot */}
                </div>
            </div>

            {/* TODO chiedere conferma comportamento errore e loading */}
            {/* {error ? <ServiceBodyError></ServiceBodyError>}
            {loading ? <ServiceBodyLoading></ServiceBodyLoading>: <ServiceBodyData data={data}></ServiceBodyData>} */}

            {/* Table Rows */}
            {error ? <Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2> : <></>}
            {loading ? <Loading /> : <></>}
            {servicesRows ? servicesRows.map((r: any) => (
                <ServiceRow serviceData={r} />
            )) : <></>}

        </Card>
    )
}