import { FC } from "react";
import { ButtonProps, ButtonWithHover } from "..";
import { Colors } from "../../../theme/Colors";
import { Small1 } from "../../Fonts/Small1";

export const ButtonOrange: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {

  return (
    <ButtonWithHover
      {...props}
      hoveredBackground='white'
      style={{ backgroundColor: Colors.PARTIALLY_FAILED_ORANGE, border: `1px solid ${Colors.PARTIALLY_FAILED_ORANGE}` }}>
      <Small1 style={{ width: '35px' }}>{children}</Small1>
    </ButtonWithHover>
  );
};