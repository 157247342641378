import React, { FC } from 'react';
import dotsIcon from '../../../svg/icons/Dots.svg';
import { IconButton } from '..';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

export const IconButtonDots: FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <IconButton {...props} icon={dotsIcon}></IconButton>
    )
}



