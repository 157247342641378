import { FC, useState } from 'react';
// import { useNavigate } from 'react-router';
import cartBlackLogo from '../../svg/icons/Header/cart-icon-black.svg';
import cartLogo from '../../svg/icons/Header/cart-icon.svg';
import messageLogo from '../../svg/icons/Header/message.svg';
import notificationLogo from '../../svg/icons/Header/notification.svg';
import openUser from '../../svg/icons/Header/open-user.svg';
import searchLogo from '../../svg/icons/Header/search-icon.svg';
import serviceLogo from '../../svg/icons/Header/service-logo.svg';
import starLogo from '../../svg/icons/Header/star.svg';
import userLogo from '../../svg/icons/Header/user-icon.svg';
import Breadcrumb, { BreadcrumbItem } from './Breadcrumb';
import styles from './Header.module.css';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  toggleSidebar: () => void,
  cartSelected?: boolean,
  nameCartSelected?: string,
  idCartSelected?: string,
  breadcrumbItems: BreadcrumbItem[]
}

export const Header: FC<HeaderProps> = ({ toggleSidebar, cartSelected, nameCartSelected, idCartSelected, breadcrumbItems }) => {
  const [isCartVisible, setIsCartVisible] = useState<boolean>(true);
  const [isMessageVisible, setIsMessageVisible] = useState<boolean>(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(true);
  const [isCartNoTextVisible, setIsCartNoTextVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const triggerCartButton = () => {
    if (cartSelected) {
      navigate(`/dashboards/carts/services?idCart=${idCartSelected}`);
    } else {
      navigate(`/dashboards/carts`);
    }

  }

  return (
    <div className={styles['header']}>
      <div className={styles['left-side']}>
        <button className={styles['logo-button']} onClick={toggleSidebar}>
          <img src={serviceLogo} alt="" />
        </button>
        <button className={styles['logo-button']} onClick={() => navigate('favorites')}>
          <img src={starLogo} alt="" />
        </button>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className={styles['right-side']}>
        <div className={styles['search-box']}>
          <img src={searchLogo} alt="Search" />
          <input type="text" placeholder="Cerca..." />
        </div>
        {isMessageVisible && <button className={styles['logo-button']}>
          <img src={messageLogo} alt="" />
        </button>}
        {isNotificationVisible && <button className={styles['logo-button']}>
          <img src={notificationLogo} alt="" />
        </button>}
        {isCartNoTextVisible && <button className={styles['logo-button']}>
          <img src={cartBlackLogo} alt="" />
        </button>}
        {isCartVisible && <button onClick={triggerCartButton} className={styles['cart-button']}>
          {
            cartSelected && nameCartSelected ?
              (<label>{nameCartSelected}</label>) :
              (<label>Choose or create the cart</label>)
          }
          <img src={cartLogo} alt="Cart" />
        </button>}
        <button className={styles['user-button']}>
          <img className={styles['user-logo']} src={userLogo} alt="User" />
          <label>Peter Surname</label>
          <img className={styles['user-info']} src={openUser} alt="" />
        </button>
      </div>
    </div>
  );
};
