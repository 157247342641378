import { CSSProperties, FC } from "react";
import { FontProps } from "../interfaces";
import { Colors } from "../../../theme/Colors";

export const Small2: FC<FontProps> = ({ children, ...props }) => {
    const styleH5: CSSProperties = {
        font: 'Inter',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: Colors.DARK_BLUE_GRAY,
        whiteSpace: 'nowrap'
    }

    return (
        <h5 {...props} style={{ ...styleH5, ...props.style }}>
            {children}
        </h5>
    )
}