import React, { FC, useState } from 'react';
import { CSSProperties } from 'styled-components';
import dotsIcon from '../../svg/icons/Dots.svg';
import { Colors } from '../../theme/Colors';


export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    icon: string
}

export const IconButton: FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(

    ({ icon, children, ...props }: ButtonProps, ref) => {
        const [hovered, setIsHovered] = useState<boolean>(false);

        const buttonStyle: CSSProperties = {
            width: '32px',
            height: '32px',
            justifyContent: 'center',
            padding: '8px 4px 8px 4px',
            borderRadius: '24px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: hovered ? Colors.LIGHT_GRAY : Colors.WHITE
        };

        return (
            <button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...props}
                style={{ ...buttonStyle, ...props.style }}
                className={props.className ?? "dots-button"}
                ref={ref}>
                <img src={icon} alt="" />
            </button>)
    }
);

IconButton.displayName = 'ButtonDots';



