import { FC } from "react";
import { ButtonProps, ButtonWithHover } from "../..";
import { Colors } from "../../../../theme/Colors";
import { Small1 } from "../../../Fonts/Small1";
import uploadIcon from '../../../../svg/icons/Upload.svg';

interface ExportQuotationButtonProps extends ButtonProps {
    rotateImage?: boolean
}

export const ExportQuotationButton: FC<ExportQuotationButtonProps> = ({ rotateImage, children, ...props }: ExportQuotationButtonProps) => {

    return (
        <ButtonWithHover
            {...props}
            hoveredBackground={Colors.PRE_APPROVED_GRAY}
            style={{
                backgroundColor: Colors.LIGHT_GRAY,
                width: '188.58px',
                height: '50px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                ...props.style
            }}>
            <Small1>{children}</Small1>
            <img style={{ transform: rotateImage ? 'rotateX(180deg)' : ''}} src={uploadIcon} alt="" />
        </ButtonWithHover>
    );
};