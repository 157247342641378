import { FC } from "react";
import { Colors } from "../../../theme/Colors";
import { Small2 } from "../../Fonts/Small2";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

export const Select: FC<SelectProps> = ({ children, ...props }) => {
    return (
        <select
            {...props}
            style={{
                width: '200px', height: '40px',
                borderRadius: '6px',
                padding: '10px 10px 10px 20px',
                backgroundColor: Colors.LIGHT_GRAY,
                ...props.style
            }}>
            {children}
        </select>
    )
}