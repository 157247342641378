import { FC } from "react";
import { Colors } from "../../../theme/Colors";

interface CardContainerProps extends React.HTMLAttributes<HTMLDivElement> { }

export const CardContainer: FC<CardContainerProps> = ({ children, ...props }) => {
    return (
        <div style={{
            height: '92px',
            padding: '30px 24px 30px 24px',
            borderRadius: '12px 12px 0px 0px',
            backgroundColor: Colors.WHITE,
            ...props.style
        }}>{children}</div>
    )
}