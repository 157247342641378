import { FC } from "react";
import { CSSProperties } from "styled-components";
import { Colors } from "../../../../theme/Colors";
import { Small1 } from "../../../Fonts/Small1";
import { Select } from "../../../Inputs/Select";
import { Small2 } from "../../../Fonts/Small2";
import { IconButtonModify } from "../../../IconButtons/Modify";
import { IconButtonDelete } from "../../../IconButtons/Delete";

interface Day2OpRowProps {
    day2OpData: any;
    onClickModify: (id: string) => void;
    onClickDelete: (id: string) => void;
}

export const Day2OpRow: FC<Day2OpRowProps> = ({ day2OpData, onClickModify, onClickDelete }) => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '18% 20% 15% 20% 15% 12%',
        gap: '10px',
        alignItems: 'center'
    }

    return (
        <div style={{
            height: '100px',
            padding: '30px 24px 30px 24px',
            borderTop: `0.5px solid #EEF0F4`,
            ...gridContainer
        }}>
            <Small1>{day2OpData.day2OpName}</Small1>
            <Small2 style={{ overflow: 'hidden' }}>{day2OpData.serviceInstanceName}</Small2>
            <Small2>{day2OpData.itemName}</Small2>
            <Small2>{day2OpData.applicationCode}</Small2>
            <Small2>
                € {day2OpData.monthlyCharge}/Month <br /> {day2OpData.setupAmount ? ` + € ${day2OpData.setupAmount} /Setup` : ''}
            </Small2>
            <div style={{ display: 'flex' }}>
                <IconButtonModify onClick={() => onClickModify(day2OpData.id)} />
                <IconButtonDelete onClick={() => onClickDelete(day2OpData.id)} />
            </div>
        </div>
    )
}