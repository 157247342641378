import { gql } from '@apollo/client/core';

export const CREATE_CART_MUTATION = gql`
  mutation CreateCart($description: String!, $name: String!, $team: String!) {
    createCart(cart: {
      description: $description,
      name: $name,
      team: $team
    }) {
      _
    }
  }
`;

export const DELETE_SERVICE_FROM_CART_MUTATION = gql`
  mutation DeleteServiceFromCart(
    $cartId: ID!,
    $serviceRequestId: ID!
  ) {
    deleteServiceFromCart(
      cartId: $cartId,
      serviceRequestId: $serviceRequestId
    ) {
      day2OpRequests {
        applicationCode
        day2OpName
        discountCharge
        id
        itemId
        itemName
        kind
        monthlyCharge
        payload {
          items {
            id
            label
            value
          }
        }
        serviceInstanceName
        serviceInstanceProvisionedId
        setupAmount
      }
      estimatedTotalCost
      estimatedTotalMonthlyCharge
      serviceRequests {
        discountCharge
        id
        monthlyCharge
        payload {
            items {
                id
                label
                value
            }
        }
        quantity
        serviceInstancePrefixName
        serviceName
        setupAmount
    }
      status
      summary {
        creationDate
        day2OpCount
        description
        id
        lastEstimatedCost
        lastUpdate
        name
        owner
        serviceCount
        team {
          id
          name
        }
      }
    }
  }
`;


export const DELETE_DAY2_OP_FROM_CART_MUTATION = gql`
  mutation DeleteDay2OpFromCart(
    $cartId: ID!,
    $day2OpRequestId: ID!
  ) {
    deleteDay2OpFromCart(
      cartId: $cartId,
      day2OpRequestId: $day2OpRequestId
    ) {
      day2OpRequests {
        applicationCode
        day2OpName
        discountCharge
        id
        itemId
        itemName
        kind
        monthlyCharge
        payload {
          items {
            id
            label
            value
          }
        }
        serviceInstanceName
        serviceInstanceProvisionedId
        setupAmount
      }
      estimatedTotalCost
      estimatedTotalMonthlyCharge
      serviceRequests {
        discountCharge
        id
        monthlyCharge
        payload {
            items {
                id
                label
                value
            }
        }
        quantity
        serviceInstancePrefixName
        serviceName
        setupAmount
    }
      status
      summary {
        creationDate
        day2OpCount
        description
        id
        lastEstimatedCost
        lastUpdate
        name
        owner
        serviceCount
        team {
          id
          name
        }
      }
    }
  }
`;

