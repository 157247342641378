import { CSSProperties, FC } from "react";
import { Colors } from "../../../theme/Colors";
import { ButtonWithHover } from "../../Button";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    transition?: string;
}

export const PaginationButton: FC<ButtonProps> = ({ children, ...props }) => {
    const buttonStyle: CSSProperties = {
        width: '32px',
        height: '32px',
        borderRadius: '24px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2px'
    }

    return (
        <ButtonWithHover
            {...props}
            hoveredBackground={Colors.LIGHT_GRAY}
            style={{ backgroundColor: Colors.WHITE, ...buttonStyle, ...props.style }}>
            {children}
        </ButtonWithHover>
    )
}