import { CSSProperties, FC, useState } from "react";
import { Small1 } from "../../Fonts/Small1";
import { ButtonPrimaryOutline } from "../../Button/ButtonPrimaryOutline";
import { IconButtonDots } from "../../IconButtons/Dots";
import { SubMenu } from "../../SubMenu";

interface CartRowProps {
    cartData: any
    selectCart: (id: string) => void,
    isSelected: boolean
}

export const CartRow: FC<CartRowProps> = ({ cartData, selectCart, isSelected }) => {
    const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '10% 20% 10% 10% 10% 15% 10% 15%',
        gap: '1px',
        alignItems: 'center'
    }

    return (
        <div style={{ height: '80px', padding: '10px 24px 10px 24px', ...gridContainer, borderTop: `0.5px solid #EEF0F4` }}>
            <Small1 >{cartData.name}</Small1>
            <Small1>{cartData.description}</Small1>
            <Small1>€{cartData.lastEstimatedCost}</Small1>
            <Small1>{cartData.serviceCount > 0 ? cartData.serviceCount : '-'}</Small1>
            <Small1>{cartData.day2OpCount > 0 ? cartData.day2OpCount : '-'}</Small1>
            <Small1>11/07/2023, 11:09:52 AM</Small1>
            <Small1>{cartData.owner}</Small1>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <ButtonPrimaryOutline onClick={(e) => { selectCart(cartData.id)} }>
                    { isSelected ? 'Selected' : 'Choose Cart' }
                </ButtonPrimaryOutline>
                <div style={{ display: "flex", flexGrow: "1", justifyContent: "end" }}>
                    <IconButtonDots
                        onMouseEnter={() => setOpenSubMenu(true)}
                        onMouseLeave={() => setOpenSubMenu(false)}></IconButtonDots>
                    {openSubMenu ?
                        <SubMenu
                            onMouseEnter={() => setOpenSubMenu(true)}
                            onMouseLeave={() => setOpenSubMenu(false)}></SubMenu> : <></>}
                </div>
            </div>
        </div>
    )
}