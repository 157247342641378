import { FC } from "react";
import { ButtonProps, ButtonWithHover } from "..";
import { Colors } from "../../../theme/Colors";
import { Small1 } from "../../Fonts/Small1";

export const ButtonYellow: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {

  return (
    <ButtonWithHover 
      {...props}
      hoveredBackground='white' 
      style={{ backgroundColor: Colors.IN_PROGRESS_YELLOW, border: `1px solid ${Colors.IN_PROGRESS_YELLOW}` }}>
      <Small1>{children}</Small1>
    </ButtonWithHover>
  );
};