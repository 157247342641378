export enum Colors {
    PRIMARY = '#33798E',
    DARK_BLUE_GRAY = '#23384A',
    LIGHT_GRAY = '#EFF4FB',
    PRE_APPROVED_GRAY = '#A2A2A2',
    COMPLETE_GREEN = '#05CD99',
    IN_PROGRESS_YELLOW = '#F2DA58',
    REJECTED_RED = '#EB582A',
    FAILED_RED = '#BC2D00',
    PARTIALLY_FAILED_ORANGE = '#FFAB2E',
    WHITE = '#FFFFFF',
    BACKGROUND = '#F2F2F2',
  }
