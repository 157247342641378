import { CSSProperties, FC, useState } from "react";
import { ButtonWithHover } from "../..";
import add from '../../../../svg/icons/Add.svg';
import { Colors } from "../../../../theme/Colors";
import { Small2 } from "../../../Fonts/Small2";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    transition?: string;
}

export const AddCartButton: FC<ButtonProps> = ({ ...props }) => {
    const style: CSSProperties = {
        width: '112px',
        height: '40px',
        borderRadius: '6px',
        padding: '8px 14px 8px 14px',
        display: 'flex',
        alignItems: 'center',
        gap: '1px',
    }

    return (
        <ButtonWithHover
            hoveredBackground={Colors.DARK_BLUE_GRAY}
            style={{ backgroundColor: Colors.COMPLETE_GREEN, ...style }}>
            <Small2 style={{ color: Colors.WHITE }}>Add Cart</Small2>
            <img src={add} alt="" />
        </ButtonWithHover>
    )
}