import { gql } from '@apollo/client/core';

export const GET_LAST_TEN_ORDER_TO_APPROVE_QUERY = gql`
  query GetLastTenOrderToApprove(
    $approvalType: ApprovalType!
  ) {
    getLastTenOrderToApprove(
      approvalType: $approvalType
    ) {
      cost
      financialUBISStatus
      financialVTSStatus
      lastUpdate
      orderId
      orderSubmittedDate
      status
      technicalApprovalStatus
    }
  }
`;

export const SEARCH_ORDERS_QUERY = gql`
  query SearchOrders(
    $fullSearchText: String!,
    # $approvalType: String,
    # $status: String,
    $limit: Int!, 
    $offset: Int!
  ) {
    searchOrders(
      input: {
        fullSearchText: $fullSearchText,
        pagination: {
        limit: $limit,
        offset: $offset
      }
      }
    ) {
      results {
        cost
        financialUBISStatus
        financialVTSStatus
        lastUpdate
        orderId
        orderSubmittedDate
        status
        technicalApprovalStatus
      },
      totalCount
    }
  }
`;

export const GET_ORDER_DETAILS_QUERY = gql`
  query GetOrderDetails(
    $id: ID!
  ) {
    getOrderDetails(
      id: $id
    ) {
      approvals {
        approvalDate
        approvalNote
        approvalStatus
        approvalType
        approvedBy
      }
      cartName
      createdBy
      creationDate
      financialDetail {
        applicationCode
        budgetType
        costCenter
        customer
        endDate
        invoiceCountry
        migrationRequestId
        multiCountry
        note
        projectId
        projectWBECode
        requestBehalfOf
        rtoRequestId
        team {
          id
          name
        }
      }
      orderedDay2OpRequests {
        applicationCode
        day2OpName
        discountCharge
        id
        kind
        monthlyCharge
        payload {
          items {
            id
            label
            value
          }
        }
        serviceInstanceName
        serviceInstanceProvisionedId
        setupAmount
        vraItemId
        vraItemName
      }
      orderedServiceRequests {
        discountCharge
        id
        monthlyCharge
        payload {
          items {
            id
            label
            value
          }
        }
        quantity
        serviceInstancePrefixName
        serviceName
        setupAmount
      }
      summary {
        cost
        financialUBISStatus
        financialVTSStatus
        lastUpdate
        orderId
        orderSubmittedDate
        status
        technicalApprovalStatus
      }
    }
  }
`;

export const GET_RELATED_ORDERS_QUERY = gql`
  query GetRelatedOrders(
    $serviceInstanceProvisionedId: ID!
  ) {
    getRelatedOrders(
      serviceInstanceProvisionedId: $serviceInstanceProvisionedId
    ) {
      cost
      financialUBISStatus
      financialVTSStatus
      lastUpdate
      orderId
      orderSubmittedDate
      status
      technicalApprovalStatus
    }
  }
`;

export const GET_NEW_ORDER_FORM_QUERY = gql`
  query GetNewOrderForm(
    $cartId: ID!
  ) {
    getNewOrderForm(
      cartId: $cartId
    ) {
      formDefinition {
        actions {
          dynActionCoords {
            id
            spawnsChildForm
            value
          }
          isPrimary
          name
          type
        }
        errors {
          code
          message
        }
        fieldGroups {
          errors {
            code
            message
          }
          fields {
            cardinalityBounds {
              left
              right
            }
            defaultValues {
              kind {
                approval {
                  actionName
                  approver {
                    userName
                  }
                  at
                  comment
                  isApproval
                }
              }
              type
            }
            errors {
              code
              message
            }
            id
            isDynamic
            isEditable
            kind {
              composite {
                emptyFormFieldDefinitions {
                  cardinalityBounds {
                    left
                    right
                  }
                  defaultValues {
                    kind {
                      approval {
                        actionName
                        approver {
                          userName
                        }
                        at
                        comment
                        isApproval
                      }
                      composite {
                        values {
                          cardinalityBounds {
                            left
                            right
                          }
                          defaultValues {
                            kind {
                              approval {
                                actionName
                                approver {
                                  userName
                                }
                                at
                                comment
                                isApproval
                              }
                              composite {
                                values {
                                  cardinalityBounds {
                                    left
                                    right
                                  }
                                  defaultValues {
                                    kind {
                                      approval {
                                        actionName
                                        approver {
                                          userName
                                        }
                                        at
                                        comment
                                        isApproval
                                      }
                                    }
                                    type
                                  }
                                  errors {
                                    code
                                    message
                                  }
                                  id
                                  isDynamic
                                  isEditable
                                  kind {
                                    composite {
                                      emptyFormFieldDefinitions {
                                        cardinalityBounds {
                                          left
                                          right
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              currency {
                                currency {
                                  displayName
                                  symbol
                                }
                                values
                              }
                              date {
                                date
                              }
                              documentObject {
                                blobRef {
                                  container
                                  downloadUrl
                                  id
                                  mimeType
                                  name
                                  previewUrl
                                  size
                                }
                              }
                              number {
                                number
                              }
                              string {
                                string
                              }
                              user {
                                user {
                                  userName
                                }
                              }
                            }
                            type
                          }
                        }
                        currency {
                          currency {
                            displayName
                            symbol
                          }
                          values
                        }
                        date {
                          date
                        }
                        documentObject {
                          blobRef {
                            container
                            downloadUrl
                            id
                            mimeType
                            name
                            previewUrl
                            size
                          }
                        }
                        number {
                          number
                        }
                        string {
                          string
                        }
                        user {
                          user {
                            userName
                          }
                        }
                      }
                      type
                    }
                  }
                  type
                }
                viewMode
              }
              currency {
                currencies {
                  displayName
                  symbol
                }
                digitsRegex
              }
              documentObject {
                allowedExtensions
              }
              formula {
                formula
              }
              genericPassword {
                withConfirmation
              }
              genericSelect {
                choices {
                  displayValue
                  id
                }
                viewType
              }
            }
            name
            placeholder
            type
            validationPredicates {
              kind {
                dateBetweenBounds {
                  dLeft
                  dRight
                }
                lengthBetweenBounds {
                  lLeft
                  lRight
                }
                regex {
                  regex
                }
                valueBetweenBounds {
                  vLeft
                  vRight
                }
              }
              type
            }
          }
        }
      }
    }
  }
`;

export const GET_TASK_FORM_QUERY = gql`
  query GetTaskForm(
    $taskId: ID!,
    $orderId: ID!
  ) {
    getTaskForm(
      taskId: $taskId,
      orderId: $orderId
    ) {
      formDefinition {
        actions {
          dynActionCoords {
            id
            spawnsChildForm
            value
          }
          isPrimary
          name
          type
        }
        errors {
          code
          message
        }
        fieldGroups {
          errors {
            code
            message
          }
          fields {
            cardinalityBounds {
              left
              right
            }
            defaultValues {
              kind {
                approval {
                  actionName
                  approver {
                    userName
                  }
                  at
                  comment
                  isApproval
                }
              }
              type
            }
            errors {
              code
              message
            }
            id
            isDynamic
            isEditable
            kind {
              composite {
                emptyFormFieldDefinitions {
                  cardinalityBounds {
                    left
                    right
                  }
                  defaultValues {
                    kind {
                      approval {
                        actionName
                        approver {
                          userName
                        }
                        at
                        comment
                        isApproval
                      }
                      composite {
                        values {
                          cardinalityBounds {
                            left
                            right
                          }
                          defaultValues {
                            kind {
                              approval {
                                actionName
                                approver {
                                  userName
                                }
                                at
                                comment
                                isApproval
                              }
                              composite {
                                values {
                                  cardinalityBounds {
                                    left
                                    right
                                  }
                                  defaultValues {
                                    kind {
                                      approval {
                                        actionName
                                        approver {
                                          userName
                                        }
                                        at
                                        comment
                                        isApproval
                                      }
                                    }
                                    type
                                  }
                                  errors {
                                    code
                                    message
                                  }
                                  id
                                  isDynamic
                                  isEditable
                                  kind {
                                    composite {
                                      emptyFormFieldDefinitions {
                                        cardinalityBounds {
                                          left
                                          right
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              currency {
                                currency {
                                  displayName
                                  symbol
                                }
                                values
                              }
                              date {
                                date
                              }
                              documentObject {
                                blobRef {
                                  container
                                  downloadUrl
                                  id
                                  mimeType
                                  name
                                  previewUrl
                                  size
                                }
                              }
                              number {
                                number
                              }
                              string {
                                string
                              }
                              user {
                                user {
                                  userName
                                }
                              }
                            }
                            type
                          }
                        }
                        currency {
                          currency {
                            displayName
                            symbol
                          }
                          values
                        }
                        date {
                          date
                        }
                        documentObject {
                          blobRef {
                            container
                            downloadUrl
                            id
                            mimeType
                            name
                            previewUrl
                            size
                          }
                        }
                        number {
                          number
                        }
                        string {
                          string
                        }
                        user {
                          user {
                            userName
                          }
                        }
                      }
                      type
                    }
                  }
                  type
                }
                viewMode
              }
              currency {
                currencies {
                  displayName
                  symbol
                }
                digitsRegex
              }
              documentObject {
                allowedExtensions
              }
              formula {
                formula
              }
              genericPassword {
                withConfirmation
              }
              genericSelect {
                choices {
                  displayValue
                  id
                }
                viewType
              }
            }
            name
            placeholder
            type
            validationPredicates {
              kind {
                dateBetweenBounds {
                  dLeft
                  dRight
                }
                lengthBetweenBounds {
                  lLeft
                  lRight
                }
                regex {
                  regex
                }
                valueBetweenBounds {
                  vLeft
                  vRight
                }
              }
              type
            }
          }
        }
      }
    }
  }
`;

