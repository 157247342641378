import { FC } from "react"
import { Colors } from "../../../theme/Colors"
import { Small2 } from "../../Fonts/Small2"

interface StepIconProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean
}

export const StepIcon: FC<StepIconProps> = ({ isActive, children, ...props}) => {
    return (
        <div style={{
            width: '32px',
            height: '32px',
            backgroundColor: isActive ? Colors.COMPLETE_GREEN : Colors.PRE_APPROVED_GRAY,
            borderRadius: '24px',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '2px'
        }}>
            <Small2 style={{ color: Colors.WHITE}}>{children}</Small2>
        </div>
    )
}
