import { CSSProperties, FC } from "react";

interface CardProps {
    children: React.ReactNode;
    style?: CSSProperties;
    className?: string
}


export const Card: FC<CardProps> = ({ children, style, className }) => {
    const defaultStyle: CSSProperties = {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '10px',
    }

    return (<div className={className ? className : "Card"} style={{...defaultStyle, ...style}}>{children}</div>)
}