import { FC, useEffect, useState } from "react";
import ErrorLogo from '../../../svg/icons/Error.svg';
import ErrorWhiteLogo from '../../../svg/icons/ErrorWhite.svg';
import { toastRootstyle, toastStyle } from "../";
import { Medium1 } from "../../Fonts/Medium1";
import { Small1 } from "../../Fonts/Small1";

interface ToastProps {
    text: string
    hide?: boolean
}

export const ErrorToast: FC<ToastProps> = ({ text, hide }) => {
    const [isShow, setIsShow] = useState<boolean>(true)

    return (
        isShow && !hide ?
            (<div style={toastRootstyle}>
                <div style={toastStyle} className="error-toast">
                    <div style={{ margin: '10px' }} className="icon">
                        <img src={ErrorLogo} alt="" />
                    </div>
                    <Small1>{text}</Small1>
                    <button onClick={() => setIsShow(false)}
                        style={{ margin: '10px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}>
                        <img src={ErrorWhiteLogo} alt="" />
                    </button>
                </div>
            </div>) :

            <></>
    )
}