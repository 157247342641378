import { CSSProperties, FC } from "react";
import { FontProps } from "../interfaces";
import { Colors } from "../../../theme/Colors";

export const Medium2: FC<FontProps> = ({ children, ...props }) => {
    const styleH3: CSSProperties = {
        font: 'Inter',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19.36px',
        color: Colors.DARK_BLUE_GRAY
    }

    return (
        <h3 style={{ ...styleH3, ...props.style }}>
            {children}
        </h3>
    )
}