import { CSSProperties, FC } from "react";
import { SubMenuButton } from "./SubMenuButton";
import { Colors } from "../../theme/Colors";

interface SubMenuProps extends React.HTMLAttributes<HTMLDivElement> {}


export const SubMenu: FC<SubMenuProps> = ({ ...props }) => {
    const style: CSSProperties = {
        width: '120px',
        height: '200px',
        position: 'absolute',
        marginTop: '26px'
    }

    return (
    <div {...props} className={props.className ?? "sub-menu"} style={style}>
        <SubMenuButton style={{ borderRadius: '12px 12px 0px 0px'}}>View Details</SubMenuButton>
        <SubMenuButton>Edit</SubMenuButton>
        <SubMenuButton>Delete</SubMenuButton>
        <SubMenuButton>Duplicate</SubMenuButton>
        <SubMenuButton style={{ borderRadius: '0px 0px 12px 12px'}}>Create Order</SubMenuButton>
    </div>
   )
}