import { ChangeEventHandler, FC } from "react";
import { Colors } from "../../../theme/Colors";
import { Small2 } from "../../Fonts/Small2";
import { SelectDisplayItems } from "../SelectDisplayItems";
import { InputSearch } from "../InputSearch";
import { DataRange } from "../../DataRange";
import { AddCartButton } from "../../Button/ButtonFilters/AddCartButton";

interface AddSearchFilterBarProps extends React.HTMLAttributes<HTMLDivElement> {
    onChangeDisplayItems: ChangeEventHandler<HTMLSelectElement>;
    onChangeInputSearch:  ChangeEventHandler<HTMLInputElement>;
}

export const AddSearchFilterBar: FC<AddSearchFilterBarProps> = ({ onChangeDisplayItems, onChangeInputSearch, ...props }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '70px',
                alignItems: 'center',
                ...props.style
            }}>
            <div>
                <SelectDisplayItems onChange={onChangeDisplayItems}/>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
                <InputSearch onChange={onChangeInputSearch} />
                <DataRange />
                <AddCartButton />
            </div>
        </div>
    )
}