import { useQuery } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSProperties } from "styled-components";
import { GET_CART_DETAIL } from "../../../graphql/cart/queries";
import { Colors } from "../../../theme/Colors";
import { ExportQuotationButton } from "../../Button/CartButton/ExportQuotationButton";
import { Card } from "../../Card";
import { DefaultCheckOutBar } from "../../CheckOutBar/DefaultCheckOutBar";
import { Medium1 } from "../../Fonts/Medium1";
import { Medium2 } from "../../Fonts/Medium2";
import { Small2 } from "../../Fonts/Small2";
import { Small3 } from "../../Fonts/Small3";
import { BreadcrumbItem } from "../../Header/Breadcrumb";
import { Status, StatusType } from "../../Status";
import { CartSteps, StateCartSteps } from "../../Steps/CartSteps";
import { PreviouslyExportedQuotations } from "../Services/PreviouslyExportedQuotations";
import { Summary } from "../Summary";
import { ServiceRow } from "./ServiceRow";
import { Day2OpRow } from "./Day2OpRow";
import { convertDateToStringMonthLetter } from "../../../utils/dateUtils";


interface CartServicesProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const CartReview: FC<CartServicesProps> = ({ changeBreadcrumb }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [cartId, setCartId] = useState<string>(params.get('idCart') as string);
    const [cartName, setCartName] = useState<string>('');
    const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);
    const [cartDetail, setCartDetail] = useState<any>();
    const [serviceRequests, setServiceRequests] = useState([]);
    const [day2opsRequests, setDay2opsRequests] = useState([]);

    useEffect(() => {
        setCartId(params.get('idCart') as string)
    }, [cartId]);

    const { loading, error, data } = useQuery(GET_CART_DETAIL, {
        skip: !params.get('idCart'),
        variables: {
            id: cartId
        },
    });

    useEffect(() => {
        if (data) {
            setCartDetail(data.getCartDetail)
            setServiceRequests(data.getCartDetail.serviceRequests);
            setDay2opsRequests(data.getCartDetail.day2OpRequests);
            setCartName(data.getCartDetail.summary.name)
        }
    }, [data]);

    useEffect(() => {
        changeBreadcrumb(
            [
                { label: 'Dashboards', link: '/dashboards' },
                { label: 'Carts', link: '/dashboards/carts' },
                { label: cartName, link: `/dashboards/carts/services?idCart=${params.get('idCart')}` },
                { label: 'Services', link: `/dashboards/carts/services?idCart=${params.get('idCart')}` },
                { label: 'Review', link: `/dashboards/carts/review?idCart=${params.get('idCart')}` },
            ]
        );
    }, [breadcrumbItems, cartName])

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '5% 25% 20% 10% 20% 15%',
        gap: '10px',
        alignItems: 'center'
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-9">
                    <Card style={{ padding: '0px' }}>
                        <CartSteps state={StateCartSteps.THIRD} />

                        <div style={{ padding: '20px 24px 0px 24px', borderTop: '1px solid #EEF0F4' }}>
                            <Medium1>Cart: {cartDetail ? cartDetail.summary.name : '--'}</Medium1>
                        </div>

                        <div style={{
                            padding: '20px 24px 16px 24px',
                            borderBottom: '1px solid #EEF0F4',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Small3>
                                Purpose: {cartDetail ? cartDetail.summary.description : '--'} <br />
                                Owner : {cartDetail ? cartDetail.summary.owner : '--'} <br />
                                <br />
                                {cartDetail ? cartDetail.summary.team.name : '--'} <br />
                            </Small3>

                            <div style={{ height: '79px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <Small2>Created On</Small2>
                                    <Small2>{cartDetail ? convertDateToStringMonthLetter(cartDetail.summary.creationDate) : '--'}</Small2>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <Small2>Last Update On</Small2>
                                    <Small2>{cartDetail ? convertDateToStringMonthLetter(cartDetail.summary.lastUpdate) : '--'}</Small2>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                    <Small2>Status</Small2>
                                    <Status status={cartDetail && cartDetail.summary.status === "OPEN" ? StatusType.OPEN : StatusType.CLOSED }></Status>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: '20px 24px 20px 24px' }}>
                            <Medium1>Cart Details</Medium1>
                        </div>

                        <div style={{
                            height: '68px',
                            padding: '15px 24px 15px 24px',
                            backgroundColor: Colors.LIGHT_GRAY,
                            ...gridContainer
                        }}>
                            <Medium2>#</Medium2>
                            <Medium2>Service Name</Medium2>
                            <Medium2>Instance Name</Medium2>
                            <Medium2>Cost</Medium2>
                            <Medium2>Quantity</Medium2>
                            <Medium2 style={{ textAlign: 'right' }}>Total Estimated Cost</Medium2>
                        </div>

                        {serviceRequests.length === 0 ? (
                            <p style={{ textAlign: 'center' }}>NESSUN ELEMENTO DA VISUALIZZARE</p>
                        ) : (
                            serviceRequests.map((service: any, index: any) => (
                                <ServiceRow
                                    serviceData={service}
                                    position={index + 1} />
                            ))
                        )}

                        <div style={{
                            height: '68px',
                            padding: '15px 24px 15px 24px',
                            backgroundColor: Colors.LIGHT_GRAY,
                            ...gridContainer
                        }}>
                            <Medium2>#</Medium2>
                            <Medium2>Day 2 Operations</Medium2>
                            <Medium2>Instance Name</Medium2>
                            <Medium2>Item Name</Medium2>
                            <Medium2>Application Code</Medium2>
                            <Medium2 style={{ textAlign: 'right' }}>Total Estimated Cost</Medium2>
                        </div>

                        {day2opsRequests.length === 0 ? (
                            <p style={{ textAlign: 'center' }}>NESSUN ELEMENTO DA VISUALIZZARE</p>
                        ) : (
                            day2opsRequests.map((day2op: any, index: any) => (
                                <Day2OpRow
                                    day2OpData={day2op} 
                                    position={index + 1}/>
                            ))
                        )}
                    </Card>

                    <div className="row mt-3">
                        <div className="col-12">
                            <DefaultCheckOutBar
                                onClickBack={() => navigate(`/dashboards/carts/services?idCart=${params.get('idCart')}`)}
                                onClickNext={() => navigate(``)} />
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Summary data={cartDetail}/>

                    <div style={{ display: 'flex', justifyContent: 'end' }} className="export-quotation mt-4">
                        <ExportQuotationButton>Export Quotation</ExportQuotationButton>
                    </div>

                    <PreviouslyExportedQuotations className="mt-4" />
                </div>
            </div>
        </div>
    )
}