export const Theme = {
  colors: {
    primary: '#33798E',
    darkBlueGray: '#23384A',
    lightGray: '#EFF4FB',
    preApprovedGray: '#A2A2A2',
    completeGreen: '#05CD99',
    inProgressYellow: '#F2DA58',
    rejectedRed: '#EB582A',
    failedRed: '#BC2D00',
    partiallyFailedOrange: '#FFAB2E',
    white: '#FFFFFF',
    background: '#F2F2F2',
  },
};

export type Color = keyof typeof Theme.colors;
