import { FC } from "react";
import { ButtonProps, ButtonWithHover } from "../..";
import previousIcon from '../../../../svg/icons/Previuous.svg';
import { Colors } from "../../../../theme/Colors";
import { Small1 } from "../../../Fonts/Small1";

export const PreviousCartButton: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {

    return (
        <ButtonWithHover
            {...props}
            hoveredBackground={Colors.DARK_BLUE_GRAY}
            hoveredColor={Colors.WHITE}
            style={{
                backgroundColor: Colors.WHITE,
                border: `1px solid ${Colors.PRIMARY}`,
                width: 'fit-content',
                height: '50px',
                borderRadius: '10px',
                display: 'flex',
                color: Colors.PRIMARY,
                justifyContent: 'space-between',
                ...props.style
            }}>
            <img src={previousIcon} alt="" />
            <Small1 style={{ color: "inherit" }}>{children}</Small1>
        </ButtonWithHover>
    );
};