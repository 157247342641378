import { CSSProperties, FC } from "react";
import { ButtonPrimaryOutline } from "../../../Button/ButtonPrimaryOutline";
import { Small1 } from "../../../Fonts/Small1";
import { ButtonYellow } from "../../../Button/ButtonYellow";
import { convertDateToStringMonthLetter } from "../../../../utils/dateUtils";

interface OrderRowProps {
    orderData: any
}

export const OrderRow: FC<OrderRowProps> = ({ orderData }) => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(4, 1fr)',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '10px'
    }

    const colStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center'
    }

    return (
        <div style={{ ...gridContainer, height: "40px", borderTop: `1px solid #EEF0F4`, paddingTop: '5px' }}>
            <div style={{...colStyle, overflow: 'hidden'}}>
                <Small1>{`ORDER # ${orderData.orderId}`}</Small1>
            </div>
            <div style={colStyle}>
                <Small1>{`€ ${orderData.cost}`}</Small1>
            </div>
            <div style={colStyle}>
                <Small1>{convertDateToStringMonthLetter(orderData.orderSubmittedDate)}</Small1>
            </div>
            <div style={colStyle}>
                <div style={{ display: "flex", flexGrow: "1", justifyContent: "end" }}>
                    <ButtonYellow>Approve</ButtonYellow>
                </div>
            </div>
        </div>
    )
}