import { useMutation, useQuery } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { CSSProperties } from "styled-components";
import { DELETE_DAY2_OP_FROM_CART_MUTATION, DELETE_SERVICE_FROM_CART_MUTATION } from "../../../graphql/cart/mutations";
import { GET_CART_DETAIL } from "../../../graphql/cart/queries";
import { Colors } from "../../../theme/Colors";
import { ExportQuotationButton } from "../../Button/CartButton/ExportQuotationButton";
import { Card } from "../../Card";
import { ThreeButtonsCheckOutBar } from "../../CheckOutBar/ThreeButtonsCheckOutBar";
import { Medium2 } from "../../Fonts/Medium2";
import { BreadcrumbItem } from "../../Header/Breadcrumb";
import { Loading } from "../../Loading";
import { CartSteps, StateCartSteps } from "../../Steps/CartSteps";
import { ErrorToast } from "../../Toasts/ErrorToast";
import { Day2OpRow } from "./Day2OpRow";
import { PreviouslyExportedQuotations } from "./PreviouslyExportedQuotations";
import { ServiceRow } from "./ServiceRow";
import { Summary } from "../Summary";

interface CartServicesProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const CartServices: FC<CartServicesProps> = ({ changeBreadcrumb }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [cartId, setCartId] = useState<string>(params.get('idCart') as string);
    const [cartName, setCartName] = useState<string>('');
    const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);
    const [serviceRequests, setServiceRequests] = useState([]);
    const [day2opsRequests, setDay2opsRequests] = useState([]);
    const [cartDetail, setCartDetail] = useState<any>();

    const [deleteServiceFromCart] = useMutation(DELETE_SERVICE_FROM_CART_MUTATION);
    const [deleteDay2OpFromCart] = useMutation(DELETE_DAY2_OP_FROM_CART_MUTATION);

    useEffect(() => {
        setCartId(params.get('idCart') as string)
    }, [cartId]);

    const { loading, error, data } = useQuery(GET_CART_DETAIL, {
        skip: !params.get('idCart'),
        variables: {
            id: cartId
        },
    });

    useEffect(() => {
        if (data) {
            setCartDetail(data.getCartDetail)
            setServiceRequests(data.getCartDetail.serviceRequests);
            setDay2opsRequests(data.getCartDetail.day2OpRequests);
            setCartName(data.getCartDetail.summary.name)
        }
    }, [data]);

    useEffect(() => {
        changeBreadcrumb(
            [
                { label: 'Dashboards', link: '/dashboards' },
                { label: 'Carts', link: '/dashboards/carts' },
                { label: cartName, link: `/dashboards/carts/services?idCart=${params.get('idCart')}` },
                { label: 'Services', link: `/dashboards/carts/services?idCart=${params.get('idCart')}` },
            ]
        );
    }, [breadcrumbItems, cartName])


    if (loading) return <Loading />;
    if (error) return <ErrorToast text="Impossibile connettersi al server!" />;

    const triggerDeleteServiceFromCart = async (id: string) => {
        try {
            const { data } = await deleteServiceFromCart({
                variables: {
                    cartId: cartId,
                    serviceRequestId: id,
                },
            });

            setServiceRequests(data.deleteServiceFromCart.serviceRequests);
        } catch (error) {
            console.error('Error deleting service from cart:', error);
        }
    }

    const triggerDeleteDay2OpFromCart = async (id: string) => {
        try {
            const { data } = await deleteDay2OpFromCart({
                variables: {
                    cartId: cartId,
                    day2OpRequestId: id,
                },
            });

            setDay2opsRequests(data.deleteDay2OpFromCart.day2OpRequests);
        } catch (error) {
            console.error('Error deleting day2ops from cart:', error);
        }
    }

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '18% 20% 15% 20% 15% 12%',
        gap: '10px',
        alignItems: 'center'
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-9">
                    <Card style={{ padding: '0px' }}>
                        <CartSteps state={StateCartSteps.SECOND} />
                        <div style={{
                            height: '49px',
                            padding: '15px 24px 15px 24px',
                            backgroundColor: Colors.LIGHT_GRAY,
                            ...gridContainer
                        }}>
                            <Medium2>Service Name</Medium2>
                            <Medium2>Instance Name</Medium2>
                            <Medium2>Cost</Medium2>
                            <Medium2>Quantity</Medium2>
                            <Medium2>Total Cost</Medium2>
                            <Medium2>Options</Medium2>
                        </div>

                        {serviceRequests.length === 0 ? (
                            <p style={{ textAlign: 'center' }}>NESSUN ELEMENTO DA VISUALIZZARE</p>
                        ) : (
                            serviceRequests.map((service: any, index: any) => (
                                <ServiceRow
                                    onClickModify={() => { }}
                                    onClickDelete={triggerDeleteServiceFromCart}
                                    serviceData={service} />
                            ))
                        )}

                        <div style={{
                            height: '49px',
                            padding: '15px 24px 15px 24px',
                            backgroundColor: Colors.LIGHT_GRAY,
                            ...gridContainer
                        }}>
                            <Medium2>Day 2 Operations</Medium2>
                            <Medium2>Instance Name</Medium2>
                            <Medium2>Item Name</Medium2>
                            <Medium2>Application Code</Medium2>
                            <Medium2>Total Cost</Medium2>
                            <Medium2>Options</Medium2>
                        </div>

                        {day2opsRequests.length === 0 ? (
                            <p style={{ textAlign: 'center' }}>NESSUN ELEMENTO DA VISUALIZZARE</p>
                        ) : (
                            day2opsRequests.map((day2op: any, index: any) => (
                                <Day2OpRow
                                    onClickModify={() => { }}
                                    onClickDelete={triggerDeleteDay2OpFromCart}
                                    day2OpData={day2op} />
                            ))
                        )}
                    </Card>

                    <div className="row mt-3">
                        <div className="col-12">
                            <ThreeButtonsCheckOutBar
                                onClickBack={() => navigate('/dashboards/carts')}
                                onClickReview={() => navigate(`/dashboards/carts/review?idCart=${cartId}`)} />
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <Summary data={cartDetail}/>

                    <div style={{ display: 'flex', justifyContent: 'end' }} className="export-quotation mt-4">
                        <ExportQuotationButton>Export Quotation</ExportQuotationButton>
                    </div>

                    <PreviouslyExportedQuotations className="mt-4"/>
                </div>
            </div>
        </div >
    )
}
