import { FC } from "react"
import { Small1 } from "../../Fonts/Small1"
import { Colors } from "../../../theme/Colors"
import sortArrows from '../../../svg/icons/SortArrows.svg';

interface SortButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    disabled?: boolean
}

export const SortButton: FC<SortButtonProps> = ({ children, disabled }) => {
    return (

        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {children}
            <button>
                {disabled ? <></> : <img src={sortArrows} alt="" />}
            </button>
        </div>
    )
}