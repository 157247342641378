import { CSSProperties, FC } from "react";
import { FontProps } from "../interfaces";
import { Colors } from "../../../theme/Colors";

export const Medium1: FC<FontProps> = ({ children, ...props }) => {
    const styleH2: CSSProperties = {
        font: 'Inter',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '21.78px',
        color: Colors.DARK_BLUE_GRAY
    }

    return (
        <h2 style={{ ...styleH2, ...props.style }}>
            {children}
        </h2>
    )
}