import React, { ChangeEvent, FC, useState } from "react";
import { Colors } from "../../../theme/Colors";
import { Small2 } from "../../Fonts/Small2";

interface InputSearchProps extends React.InputHTMLAttributes<HTMLInputElement> {

}

export const InputSearch: FC<InputSearchProps> = ({ ...props }) => {
    const [hovered, setHovered] = useState<boolean>(false)

    return (
        <input
            {...props}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            placeholder="Search..."
            style={{
                width: '260px', height: '40px',
                borderRadius: '6px',
                padding: '10px 10px 10px 20px',
                backgroundColor: Colors.LIGHT_GRAY,
                border: `1px solid ${hovered ? Colors.COMPLETE_GREEN : Colors.LIGHT_GRAY}`
            }} type="text" />
    )
}