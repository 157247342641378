import React from 'react';
import styles from './Breadcrumb.module.css';
import { useNavigate } from 'react-router';

export interface BreadcrumbItem {
  label: string;
  link?: string;
}

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();

  const performRedirect = (link: string | undefined) => {
    navigate(link as string);
  }

  return (
    <div className={styles["breadcrumb"]}>
      <div className={styles["breadcrumb-container"]}>
        {items && items.map((item, index) => (
          <React.Fragment key={index}>
            {item.link ? (
              index === 0 ?
                (<label style={{ cursor: 'pointer' }} className={styles["first-crumb"]} onClick={() => performRedirect(item.link)}>{item.label}</label>) :
                (<label style={{ cursor: 'pointer' }} onClick={() => performRedirect(item.link)}>{item.label}</label>)
            ) : (
              <span>{item.label}</span>
            )}
            {index !== items.length - 1 && <span className={styles["separator"]}>/</span>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
