import { FC, useEffect, useMemo, useState } from "react";
import { BreadcrumbItem } from "../../Header/Breadcrumb";
import { Card } from "../../Card";
import { Large } from "../../Fonts/Large";
import { RightSearchFilterBar } from "../../SearchFilterBar/RightSearchFilterBar";
import { SortButton } from "../../Carts/SortButton";
import { Small1 } from "../../Fonts/Small1";
import { CSSProperties } from "styled-components";
import { Colors } from "../../../theme/Colors";
import { ButtonYellow } from "../../Button/ButtonYellow";
import { ProgressBar, ProgressBarStepStatus } from "../../ProgressBar";
import { HeaderProgressBar } from "../../ProgressBar/HeaderProgressBar";
import { useQuery } from "@apollo/client";
import { SEARCH_ORDERS_QUERY } from "../../../graphql/order/queries";
import { timestampToDateString } from "../../../utils/dateUtils";
import { formatNumberAsFloat } from "../../../utils/numberUtils";
import { OrderRow } from "../OrderRow";
import { Small2 } from "../../Fonts/Small2";
import { PaginationGroup } from "../../Pagination/PaginationGroup";
import { Medium2 } from "../../Fonts/Medium2";
import { Loading } from "../../Loading";

interface AllOrdersProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const AllOrders: FC<AllOrdersProps> = ({ changeBreadcrumb }) => {
    const breadcrumbItems = useMemo(() => [
        { label: 'All Orders', link: '/all-orders' }
    ], []);

    useEffect(() => {
        changeBreadcrumb(breadcrumbItems);
    }, [breadcrumbItems, changeBreadcrumb]);

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);
    const [filter, setFilter] = useState<string>('');

    const { loading, error, data } = useQuery(SEARCH_ORDERS_QUERY, {
        // TODO: Aggiungere ordinamento lista
        variables: {
            fullSearchText: filter,
            limit: limit,
            offset: offset
        },
    });

    console.log(data);


    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '15% 15% 10% 50% 6%',
        gap: '10px',
        alignItems: 'center'
    }

    const textStyle: CSSProperties = {
        color: Colors.PRE_APPROVED_GRAY,
        whiteSpace: 'break-spaces'
    }

    return (
        <Card>
            {/* Commands */}
            <div style={{ padding: "20px", display: "flex", justifyContent: "space-between", paddingBottom: "32px" }}>
                <Large>All Orders</Large>
            </div>

            {/* Filters */}
            <RightSearchFilterBar
                onChangeDisplayItems={(e) => { setLimit(parseInt(e.target.value)) }}
                onChangeInputSearch={(e) => { setFilter(e.target.value) }}
                style={{ padding: '11px 20px 11px 20px' }} />

            {/* Sorting */}
            <div style={{ height: '50px', padding: '10px 24px 10px 24px', ...gridContainer, borderTop: `0.5px solid #EEF0F4` }}>
                <SortButton style={{ display: 'flex', alignItems: 'center' }}>
                    <Small1 style={{ ...textStyle }}>
                        Orders ID
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Last Updated On
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Total Cost
                    </Small1>
                </SortButton>
                <HeaderProgressBar />
                <Small1 style={{ color: Colors.PRE_APPROVED_GRAY, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    Options
                </Small1>
            </div>

            {/* Table Rows */}
            {error ? <Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2> : <></>}
            {loading ? <Loading /> : <></>}
            {data ? data.searchOrders.results.map((order: any) => (
                <OrderRow orderData={order} />
            )) : <></>}

            <div style={{
                height: '80px',
                padding: '24px',
                borderTop: `0.5px solid #EEF0F4`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'

            }}>
                <Small2>Showing {limit} to {offset + 1} of {Math.ceil((data ? data.searchOrders.totalCount : 0) / limit)}</Small2>
                <PaginationGroup
                    limit={limit}
                    offset={offset}
                    totalCount={data ? data.searchOrders.totalCount : 0}
                    setOffset={setOffset}
                ></PaginationGroup>
            </div>

        </Card>
    )
}