import { createReducer } from '@reduxjs/toolkit';
import { addLoading, removeLoading } from './actions';

export interface UtilsState {
  loading: number;
}

const defaultState: UtilsState = {
  loading: 0,
};

export const utilsReducer = createReducer<UtilsState>(defaultState, {
  [addLoading.type]: (state): UtilsState => ({
    ...state,
    loading: state.loading + 1,
  }),
  [removeLoading.type]: (state): UtilsState => ({
    ...state,
    loading: Math.max(state.loading - 1, 0),
  }),
});
