import { CSSProperties, FC } from "react";
import { ButtonPrimaryOutline } from "../../../Button/ButtonPrimaryOutline";
import { Small1 } from "../../../Fonts/Small1";

interface Day2OpRowProps {
    day2OpData: any
}

export const Day2OpRow: FC<Day2OpRowProps> = ({ day2OpData }) => {    
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '1fr auto 1fr',
        gap: '10px'
    }

    const colStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'center'
    }

    return (
        <div style={{ ...gridContainer, height: "34px", borderTop: `1px solid #EEF0F4`, paddingTop: '5px' }}>
            <div style={colStyle}>
                <Small1>{day2OpData.name}</Small1>
            </div>
            <div style={{...colStyle, width: '70px'}}>
                <Small1>{day2OpData.itemsNumber}</Small1>
            </div>
            <div style={colStyle}>
                <div style={{ display: "flex", flexGrow: "1", justifyContent: "end" }}>
                    <ButtonPrimaryOutline>Open</ButtonPrimaryOutline>
                </div>
            </div>
        </div>
    )
}