import { CSSProperties, FC } from "react";
import { Colors } from "../../../../theme/Colors";
import { ExportQuotationButton } from "../../../Button/CartButton/ExportQuotationButton";
import { Card } from "../../../Card";
import { Medium2 } from "../../../Fonts/Medium2";
import { Small2 } from "../../../Fonts/Small2";
import { Small3 } from "../../../Fonts/Small3";

interface PreviouslyExportedQuotationsProps {
    className?: string
}

export const PreviouslyExportedQuotations: FC<PreviouslyExportedQuotationsProps> = ({ className }) => {
    const buttonDownloadQuotationStyle: CSSProperties = {
        backgroundColor: Colors.LIGHT_GRAY,
        color: 'grey',
        fontSize: 'smaller',
        padding: '5px 10px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center'
    }


    return (
        <Card className={className} style={{ padding: '0px' }}>
            {/* Header */}
            <div style={{
                height: '80px',
                borderRadius: '12px 12px 0px 0px',
                borderBottom: '0.5px solid #EEF0F4',
                padding: '30px 24px 30px 24px'
            }}>
                <Medium2>Previously exported quotations</Medium2>
            </div>

            <div style={{
                padding: '16px 24px 16px 24px',
                height: '82px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Small3>11/07/2022</Small3>
                <Small3>peter123</Small3>
                <ExportQuotationButton
                    rotateImage={true}
                    style={{ padding: '5px 10px', width: 'fit-content' }}>Download</ExportQuotationButton>
            </div>

            <div style={{
                padding: '0px 24px 16px 24px',
                height: '82px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Small3>11/07/2022</Small3>
                <Small3>peter123</Small3>
                <ExportQuotationButton
                    rotateImage={true}
                    style={{ padding: '5px 10px', width: 'fit-content' }}>Download</ExportQuotationButton>
            </div>
        </Card>
    )
}