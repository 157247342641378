import { FC } from "react";
import { Colors } from "../../../theme/Colors";
import { Small2 } from "../../Fonts/Small2";

interface SelectDisplayItemsProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

export const SelectDisplayItems: FC<SelectDisplayItemsProps> = ({ ...props }) => {
    return (
        <select
            {...props}
            style={{
                width: '200px', height: '40px',
                borderRadius: '6px',
                padding: '10px 10px 10px 20px',
                backgroundColor: Colors.LIGHT_GRAY
            }}>
            <option value="5"><Small2>Display 5 instances</Small2></option>
            <option value="15"><Small2>Display 15 instances</Small2></option>
            <option value="25"><Small2>Display 25 instances</Small2></option>
            <option value="50"><Small2>Display 50 instances</Small2></option>
        </select>
    )
}