import { gql } from '@apollo/client/core';

export const GET_LAST_FIVE_CARTS = gql`
  query GetLastFiveCarts {
    getLastFiveCarts {
      creationDate
      day2OpCount
      description
      id
      lastEstimatedCost
      lastUpdate
      owner
      name
      serviceCount
    }
  }
`;

export const GET_CART_DETAIL = gql`
  query GetCartDetail($id: ID!) {
    getCartDetail(id: $id) {
      day2OpRequests {
        applicationCode
        day2OpName
        discountCharge
        id
        itemId
        itemName
        kind
        monthlyCharge
        payload {
          items {
            id
            label
            value
          }
        }
        serviceInstanceName
        serviceInstanceProvisionedId
        setupAmount
      }
      estimatedTotalCost
      estimatedTotalMonthlyCharge
      serviceRequests {
        discountCharge
        id
        monthlyCharge
        payload {
            items {
                id
                label
                value
            }
        }
        quantity
        serviceInstancePrefixName
        serviceName
        setupAmount
    }
      status
      summary {
        creationDate
        day2OpCount
        description
        id
        lastEstimatedCost
        lastUpdate
        name
        owner
        serviceCount
        team {
          id
          name
        },
        status
      }
    }
  }
`;

export const SEARCH_CARTS = gql`
  query SearchCarts($filter: String!, $limit: Int!, $offset: Int!) {
    searchCarts(input: {
      filter: $filter,
      pagination: {
        limit: $limit,
        offset: $offset
      }
    }) {
      results {
        creationDate
        day2OpCount
        description
        id
        lastEstimatedCost
        lastUpdate
        owner
        name
        serviceCount
      }
      totalCount
    }
  }
`;

export const SERVICE_PAYLOAD_FORM_QUERY = gql`
  query ServicePayloadForm($cartId: ID!, $serviceName: String!) {
    servicePayloadForm(cartId: $cartId, serviceName: $serviceName) {
      formDefinition {
        actions {
          dynActionCoords {
            id
            spawnsChildForm
            value
          }
          isPrimary
          name
          type
        }
        errors {
          code
          message
        }
        fieldGroups {
          errors {
            code
            message
          }
          fields {
            cardinalityBounds {
              left
              right
            }
            defaultValues {
              kind {
                approval {
                  actionName
                  approver {
                    userName
                  }
                  at
                  comment
                  isApproval
                }
                composite {
                  values {
                    cardinalityBounds {
                      left
                      right
                    }
                    defaultValues {
                      kind {
                        approval {
                          actionName
                          approver {
                            userName
                          }
                          at
                          comment
                          isApproval
                        }
                        composite {
                          values {
                            cardinalityBounds {
                              left
                              right
                            }
                            defaultValues {
                              kind {
                                approval {
                                  actionName
                                  approver {
                                    userName
                                  }
                                  at
                                  comment
                                  isApproval
                                }
                                composite {
                                  values {
                                    cardinalityBounds {
                                      left
                                      right
                                    }
                                    defaultValues {
                                      kind {
                                        approval {
                                          actionName
                                          approver {
                                            userName
                                          }
                                          at
                                          comment
                                          isApproval
                                        }
                                        composite {
                                          values {
                                            cardinalityBounds {
                                              left
                                              right
                                            }
                                            defaultValues {
                                              kind {
                                                approval {
                                                  actionName
                                                  approver {
                                                    userName
                                                  }
                                                  at
                                                  comment
                                                  isApproval
                                                }
                                              }
                                              type
                                            }
                                            errors {
                                              code
                                              message
                                            }
                                            id
                                            isDynamic
                                            isEditable
                                            kind {
                                              composite {
                                                emptyFormFieldDefinitions {
                                                  cardinalityBounds {
                                                    left
                                                    right
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                        currency {
                                          currency {
                                            displayName
                                            symbol
                                          }
                                          values
                                        }
                                        date {
                                          date
                                        }
                                        documentObject {
                                          blobRef {
                                            container
                                            downloadUrl
                                            id
                                            mimeType
                                            name
                                            previewUrl
                                            size
                                          }
                                        }
                                        number {
                                          number
                                        }
                                        string {
                                          string
                                        }
                                        user {
                                          user {
                                            userName
                                          }
                                        }
                                      }
                                      type
                                    }
                                  }
                                }
                                currency {
                                  currency {
                                    displayName
                                    symbol
                                  }
                                  values
                                }
                                date {
                                  date
                                }
                                documentObject {
                                  blobRef {
                                    container
                                    downloadUrl
                                    id
                                    mimeType
                                    name
                                    previewUrl
                                    size
                                  }
                                }
                                number {
                                  number
                                }
                                string {
                                  string
                                }
                                user {
                                  user {
                                    userName
                                  }
                                }
                              }
                              type
                            }
                          }
                        }
                        currency {
                          currency {
                            displayName
                            symbol
                          }
                          values
                        }
                        date {
                          date
                        }
                        documentObject {
                          blobRef {
                            container
                            downloadUrl
                            id
                            mimeType
                            name
                            previewUrl
                            size
                          }
                        }
                        number {
                          number
                        }
                        string {
                          string
                        }
                        user {
                          user {
                            userName
                          }
                        }
                      }
                      type
                    }
                  }
                }
                currency {
                  currency {
                    displayName
                    symbol
                  }
                  values
                }
                date {
                  date
                }
                documentObject {
                  blobRef {
                    container
                    downloadUrl
                    id
                    mimeType
                    name
                    previewUrl
                    size
                  }
                }
                number {
                  number
                }
                string {
                  string
                }
                user {
                  user {
                    userName
                  }
                }
              }
              type
            }
            isDynamic
            isEditable
            kind {
              composite {
                emptyFormFieldDefinitions {
                  cardinalityBounds {
                    left
                    right
                  }
                }
              }
              viewMode
            }
            currency {
              currency {
                displayName
                symbol
              }
              digitsRegex
            }
            documentObject {
              allowedExtensions
            }
            formula {
              formula
            }
            genericPassword {
              withConfirmation
            }
            genericSelect {
              choices {
                displayValue
                id
              }
              viewType
            }
            name
            placeholder
            type
            validationPredicates {
              kind {
                dateBetweenBounds {
                  dLeft
                  dRight
                }
                lengthBetweenBounds {
                  lLeft
                  lRight
                }
                regex {
                  regex
                }
                valueBetweenBounds {
                  vLeft
                  vRight
                }
              }
              type
            }
          }
        }
      }
    }
  }
`;

export const DAY2_OP_PAYLOAD_FORM_QUERY = gql`
  query Day2OpPayloadForm($cartId: ID!, $day2OpName: String!) {
    day2OpPayloadForm(cartId: $cartId, day2OpName: $day2OpName) {
      formDefinition {
        actions {
          dynActionCoords {
            id
            spawnsChildForm
            value
          }
          isPrimary
          name
          type
        }
        errors {
          code
          message
        }
        fieldGroups {
          errors {
            code
            message
          }
          fields {
            cardinalityBounds {
              left
              right
            }
            defaultValues {
              kind {
                approval {
                  actionName
                  approver {
                    userName
                  }
                  at
                  comment
                  isApproval
                }
                composite {
                  values {
                    cardinalityBounds {
                      left
                      right
                    }
                    defaultValues {
                      kind {
                        approval {
                          actionName
                          approver {
                            userName
                          }
                          at
                          comment
                          isApproval
                        }
                        composite {
                          values {
                            cardinalityBounds {
                              left
                              right
                            }
                            defaultValues {
                              kind {
                                approval {
                                  actionName
                                  approver {
                                    userName
                                  }
                                  at
                                  comment
                                  isApproval
                                }
                                composite {
                                  values {
                                    cardinalityBounds {
                                      left
                                      right
                                    }
                                    defaultValues {
                                      kind {
                                        approval {
                                          actionName
                                          approver {
                                            userName
                                          }
                                          at
                                          comment
                                          isApproval
                                        }
                                        composite {
                                          values {
                                            cardinalityBounds {
                                              left
                                              right
                                            }
                                            defaultValues {
                                              kind {
                                                approval {
                                                  actionName
                                                  approver {
                                                    userName
                                                  }
                                                  at
                                                  comment
                                                  isApproval
                                                }
                                              }
                                              type
                                            }
                                            errors {
                                              code
                                              message
                                            }
                                            id
                                            isDynamic
                                            isEditable
                                            kind {
                                              composite {
                                                emptyFormFieldDefinitions {
                                                  cardinalityBounds {
                                                    left
                                                    right
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                        currency {
                                          currency {
                                            displayName
                                            symbol
                                          }
                                          values
                                        }
                                        date {
                                          date
                                        }
                                        documentObject {
                                          blobRef {
                                            container
                                            downloadUrl
                                            id
                                            mimeType
                                            name
                                            previewUrl
                                            size
                                          }
                                        }
                                        number {
                                          number
                                        }
                                        string {
                                          string
                                        }
                                        user {
                                          user {
                                            userName
                                          }
                                        }
                                      }
                                      type
                                    }
                                  }
                                }
                                currency {
                                  currency {
                                    displayName
                                    symbol
                                  }
                                  values
                                }
                                date {
                                  date
                                }
                                documentObject {
                                  blobRef {
                                    container
                                    downloadUrl
                                    id
                                    mimeType
                                    name
                                    previewUrl
                                    size
                                  }
                                }
                                number {
                                  number
                                }
                                string {
                                  string
                                }
                                user {
                                  user {
                                    userName
                                  }
                                }
                              }
                              type
                            }
                          }
                        }
                        currency {
                          currency {
                            displayName
                            symbol
                          }
                          values
                        }
                        date {
                          date
                        }
                        documentObject {
                          blobRef {
                            container
                            downloadUrl
                            id
                            mimeType
                            name
                            previewUrl
                            size
                          }
                        }
                        number {
                          number
                        }
                        string {
                          string
                        }
                        user {
                          user {
                            userName
                          }
                        }
                      }
                      type
                    }
                  }
                }
                currency {
                  currency {
                    displayName
                    symbol
                  }
                  values
                }
                date {
                  date
                }
                documentObject {
                  blobRef {
                    container
                    downloadUrl
                    id
                    mimeType
                    name
                    previewUrl
                    size
                  }
                }
                number {
                  number
                }
                string {
                  string
                }
                user {
                  user {
                    userName
                  }
                }
              }
              type
            }
            isDynamic
            isEditable
            kind {
              composite {
                emptyFormFieldDefinitions {
                  cardinalityBounds {
                    left
                    right
                  }
                }
              }
              viewMode
            }
            currency {
              currencies {
                displayName
                symbol
              }
              digitsRegex
            }
            documentObject {
              allowedExtensions
            }
            formula {
              formula
            }
            genericPassword {
              withConfirmation
            }
            genericSelect {
              choices {
                displayValue
                id
              }
              viewType
            }
            name
            placeholder
            type
            validationPredicates {
              kind {
                dateBetweenBounds {
                  dLeft
                  dRight
                }
                lengthBetweenBounds {
                  lLeft
                  lRight
                }
                regex {
                  regex
                }
                valueBetweenBounds {
                  vLeft
                  vRight
                }
              }
              type
            }
          }
        }
      }
    }
  }
`;

export const REOPEN_SERVICE_PAYLOAD_FORM_QUERY = gql`
  query ReopenServicePayloadForm(
    $cartId: ID!,
    $serviceName: String!,
    $serviceRequestId: ID!
  ) {
    reopenServicePayloadForm(
      cartId: $cartId,
      serviceName: $serviceName,
      serviceRequestId: $serviceRequestId
    ) {
      formDefinition {
        actions {
          dynActionCoords {
            id
            spawnsChildForm
            value
          }
          isPrimary
          name
          type
        }
        errors {
          code
          message
        }
        fieldGroups {
          errors {
            code
            message
          }
          fields {
            cardinalityBounds {
              left
              right
            }
            defaultValues {
              kind {
                approval {
                  actionName
                  approver {
                    userName
                  }
                  at
                  comment
                  isApproval
                }
                composite {
                  values {
                    cardinalityBounds {
                      left
                      right
                    }
                    defaultValues {
                      kind {
                        approval {
                          actionName
                          approver {
                            userName
                          }
                          at
                          comment
                          isApproval
                        }
                        composite {
                          values {
                            cardinalityBounds {
                              left
                              right
                            }
                            defaultValues {
                              kind {
                                approval {
                                  actionName
                                  approver {
                                    userName
                                  }
                                  at
                                  comment
                                  isApproval
                                }
                                composite {
                                  values {
                                    cardinalityBounds {
                                      left
                                      right
                                    }
                                    defaultValues {
                                      kind {
                                        approval {
                                          actionName
                                          approver {
                                            userName
                                          }
                                          at
                                          comment
                                          isApproval
                                        }
                                        composite {
                                          values {
                                            cardinalityBounds {
                                              left
                                              right
                                            }
                                            defaultValues {
                                              kind {
                                                approval {
                                                  actionName
                                                  approver {
                                                    userName
                                                  }
                                                  at
                                                  comment
                                                  isApproval
                                                }
                                              }
                                              type
                                            }
                                            errors {
                                              code
                                              message
                                            }
                                            id
                                            isDynamic
                                            isEditable
                                            kind {
                                              composite {
                                                emptyFormFieldDefinitions {
                                                  cardinalityBounds {
                                                    left
                                                    right
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                        currency {
                                          currency {
                                            displayName
                                            symbol
                                          }
                                          values
                                        }
                                        date {
                                          date
                                        }
                                        documentObject {
                                          blobRef {
                                            container
                                            downloadUrl
                                            id
                                            mimeType
                                            name
                                            previewUrl
                                            size
                                          }
                                        }
                                        number {
                                          number
                                        }
                                        string {
                                          string
                                        }
                                        user {
                                          user {
                                            userName
                                          }
                                        }
                                      }
                                      type
                                    }
                                  }
                                }
                                currency {
                                  currency {
                                    displayName
                                    symbol
                                  }
                                  values
                                }
                                date {
                                  date
                                }
                                documentObject {
                                  blobRef {
                                    container
                                    downloadUrl
                                    id
                                    mimeType
                                    name
                                    previewUrl
                                    size
                                  }
                                }
                                number {
                                  number
                                }
                                string {
                                  string
                                }
                                user {
                                  user {
                                    userName
                                  }
                                }
                              }
                              type
                            }
                          }
                        }
                        currency {
                          currency {
                            displayName
                            symbol
                          }
                          values
                        }
                        date {
                          date
                        }
                        documentObject {
                          blobRef {
                            container
                            downloadUrl
                            id
                            mimeType
                            name
                            previewUrl
                            size
                          }
                        }
                        number {
                          number
                        }
                        string {
                          string
                        }
                        user {
                          user {
                            userName
                          }
                        }
                      }
                      type
                    }
                  }
                }
                currency {
                  currency {
                    displayName
                    symbol
                  }
                  values
                }
                date {
                  date
                }
                documentObject {
                  blobRef {
                    container
                    downloadUrl
                    id
                    mimeType
                    name
                    previewUrl
                    size
                  }
                }
                number {
                  number
                }
                string {
                  string
                }
                user {
                  user {
                    userName
                  }
                }
              }
              type
            }
            isDynamic
            isEditable
            kind {
              composite {
                emptyFormFieldDefinitions {
                  cardinalityBounds {
                    left
                    right
                  }
                }
              }
              viewMode
            }
            currency {
              currencies {
                displayName
                symbol
              }
              digitsRegex
            }
            documentObject {
              allowedExtensions
            }
            formula {
              formula
            }
            genericPassword {
              withConfirmation
            }
            genericSelect {
              choices {
                displayValue
                id
              }
              viewType
            }
            name
            placeholder
            type
            validationPredicates {
              kind {
                dateBetweenBounds {
                  dLeft
                  dRight
                }
                lengthBetweenBounds {
                  lLeft
                  lRight
                }
                regex {
                  regex
                }
                valueBetweenBounds {
                  vLeft
                  vRight
                }
              }
              type
            }
          }
        }
      }
    }
  }
`;




