import { FC, useEffect, useState } from "react";
import { Card } from "../../Card";
import starUnflagged from '../../../svg/icons/Header/star-unflagged.svg';
import starFlagged from '../../../svg/icons/Header/star.svg';
import cloud from '../../../svg/icons/Cloud.svg';
import { Medium1 } from "../../Fonts/Medium1";
import { Small2 } from "../../Fonts/Small2";
import { Colors } from "../../../theme/Colors";
import { useMutation } from "@apollo/client";
import { TOGGLE_FAVOURITE_DAY2OP_MUTATION, TOGGLE_FAVOURITE_SERVICE_MUTATION } from "../../../graphql/catalog/mutations";
import { ErrorToast } from "../../Toasts/ErrorToast";
import { errorToString } from "../../../utils/errors";

export enum ItemInCatalogType {
    SERVICE,
    DAY_2_OP
}

interface ItemInCatalogProps {
    data: any;
    type: ItemInCatalogType;
    refetch: () => void;
}

export const ItemInCatalog: FC<ItemInCatalogProps> = ({ data, type, refetch }) => {        
    const [toggleFavouriteService] = useMutation(TOGGLE_FAVOURITE_SERVICE_MUTATION);
    const [toggleFavouriteDay2Op] = useMutation(TOGGLE_FAVOURITE_DAY2OP_MUTATION);

    const [toastHide, setToastHide] = useState<boolean>(true);
    const [toastText, setToastText] = useState<string>('');

    const toggleService = async () => {
        try {
            const { data: result } = await toggleFavouriteService({
                variables: {
                    serviceName: data.name
                },
            });

            refetch();
            setToastHide(true);
        } catch (error) {
            setToastText(errorToString(error))
            setToastHide(false);

            setTimeout(() => {
                setToastHide(true);
            }, 5000)
        }
    }

    const toggleDay2Op = async () => {
        try {
            const { data: result } = await toggleFavouriteDay2Op({
                variables: {
                    day2OpName: data.name
                },
            });

            refetch();
            setToastHide(true);
        } catch (error) {
            setToastText(errorToString(error))
            setToastHide(false);
            setTimeout(() => {
                setToastHide(true);
            }, 5000)
        }
    }

    return (
        <Card style={{ padding: '0px 0px 20px 0px', cursor: 'pointer' }}>
            <ErrorToast text={toastText} hide={toastHide}></ErrorToast>
            {/* Top Section */}
            <div style={{ padding: '20px 24px 20px 24px', borderBottom: '0.5px solid #EFF4FB' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <img src={cloud} alt="" />
                    {data.favourite ?
                        <img
                            style={{ cursor: 'pointer' }}
                            onClick={type === ItemInCatalogType.SERVICE ? toggleService : toggleDay2Op}
                            src={starFlagged} alt="" /> :
                        <img
                            style={{ cursor: 'pointer' }}
                            onClick={type === ItemInCatalogType.SERVICE ? toggleService : toggleDay2Op}
                            src={starUnflagged} alt="" />}
                </div>
                <Medium1 style={{ marginTop: '10px', height: '55px' }}>{data.name}</Medium1>
            </div>

            {/* Bottom Section */}
            <Small2 style={{
                color: Colors.PRE_APPROVED_GRAY,
                padding: '16px 24px 0px 24px',
                height: '40px',
                whiteSpace: 'break-spaces'
            }}>{data.description}</Small2>
        </Card>
    )
}