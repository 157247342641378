
import { CSSProperties, FC, useEffect, useState } from "react";
import { Card } from "../../Card";
import { Small1 } from "../../Fonts/Small1";
import { ButtonPrimaryFilled } from "../../Button/ButtonPrimaryFilled";
import { Large } from "../../Fonts/Large";
import { Colors } from "../../../theme/Colors";
import { Day2OpRow } from "./Day2OpRow";
import { useQuery } from "@apollo/client";
import { GET_DAY2_OP_CATEGORIES_QUERY } from "../../../graphql/catalog/queries";
import { Medium2 } from "../../Fonts/Medium2";
import { Loading } from "../Loading";
import { POLL_INTERVAL } from "../../../utils/constants";

export const Day2Ops: FC = () => {
    const [day2OpsRows, setDay2OpsRows] = useState<any>();

    const { loading, error, data } =
        useQuery(GET_DAY2_OP_CATEGORIES_QUERY, {
            pollInterval: POLL_INTERVAL
        });


    useEffect(() => {
        if (data) {
            setDay2OpsRows([
                {
                    name: 'Linux Server',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Linux Server")).length
                },
                {
                    name: 'Windows Server',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Windows Server")).length
                },
                {
                    name: 'Apache with JBoss Server',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Apache with JBoss Server")).length
                },
                {
                    name: 'Apache with Tomcat Server',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Apache with Tomcat Server")).length
                },
                {
                    name: 'Delphix appliance',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Delphix appliance")).length
                },
                {
                    name: 'Deployment Manager',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Deployment Manager")).length
                },
                {
                    name: 'IHS Server',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("IHS Server")).length
                },
                {
                    name: 'IIS System',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("IIS System")).length
                },
                {
                    name: 'JBoss Sytem',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("JBoss Sytem")).length
                },
                {
                    name: 'SAP on Linux Server',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("SAP on Linux Server")).length
                },
                {
                    name: 'SQL System',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("SQL System")).length
                },
                {
                    name: 'Tomcat System',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("Tomcat System")).length
                },
                {
                    name: 'WAS Cluster',
                    itemsNumber: data.getDay2OpCategories.filter((e: any) => e.categories.includes("WAS Cluster")).length
                }
            ])
        }

    }, [data])


    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(3, 1fr)',
        gridTemplateColumns: '1fr auto 1fr',
        gap: '10px'
    }

    return (
        <Card style={{ padding: "24px 32px", height: "574px", opacity: loading ? '0.5' : '1' }}>

            {/* Commands */}
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "32px" }}>
                <Large>Day 2 Operations</Large>
                <ButtonPrimaryFilled>
                    View All
                </ButtonPrimaryFilled>
            </div>

            {/* Table Heading */}
            <div style={{ ...gridContainer, height: "30px" }}>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>For Service</Small1>
                </div>
                <div>
                    <Small1 style={{ color: Colors.PRE_APPROVED_GRAY }}>Day 2 Ops</Small1>
                </div>
                <div>
                    {/* Empty Slot */}
                </div>
            </div>

            {/* Table Rows */}
            {error ? <Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2> : <></>}
            {loading ? <Loading /> : <></>}
            {day2OpsRows ? day2OpsRows.map((r: any) => (                
                <Day2OpRow day2OpData={r} />
            )) : <></>}

        </Card>
    )
}