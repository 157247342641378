import { createAction } from '@reduxjs/toolkit';

enum Actions {
  ADD_LOADING = 'ADD_LOADING',
  REMOVE_LOADING = 'REMOVE_LOADING',
}

export const addLoading = createAction<undefined>(Actions.ADD_LOADING);

export const removeLoading = createAction<undefined>(Actions.REMOVE_LOADING);
