import { FC, useCallback, useEffect, useMemo } from "react";
import { Services } from "./Services";

import { useNavigate } from "react-router-dom";
import { AddCartButton } from "../Button/AddCardButton";
import { BreadcrumbItem } from "../Header/Breadcrumb";
import { Carts } from "./Carts";
import { Day2Ops } from "./Day2Ops";
import { Orders } from "./Orders";

interface DashboardProps {
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const Dashboard: FC<DashboardProps> = ({ changeBreadcrumb }) => {
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const breadcrumbItems = useMemo(() => [
        { label: 'Dashboards', link: '/dashboards' }
    ], []);

    useEffect(() => {
        changeBreadcrumb(breadcrumbItems);
    }, [breadcrumbItems, changeBreadcrumb]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-xxl-6">
                    <div className="row">
                        <div className="col-12">
                            <Services />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Day2Ops />
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6">
                    <Carts viewAllOnClick={() => navigate('/dashboards/carts')}/>
                    <div className="mt-3"></div>
                    <Orders />
                    <div className="mt-4">
                        <AddCartButton >
                            
                        </AddCartButton>
                    </div>
                </div>
            </div>
        </div>
    )
}