import { FC } from "react";
import previousIcon from '../../../../svg/icons/Previuous.svg';
import { Colors } from "../../../theme/Colors";
import { ButtonProps, ButtonWithHover } from "..";
import { Small1 } from "../../Fonts/Small1";

export const CartButton: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {

    return (
        <ButtonWithHover
            {...props}
            hoveredBackground={Colors.DARK_BLUE_GRAY}
            style={{
                backgroundColor: Colors.PRIMARY,
                width: 'fit-content',
                height: '50px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                ...props.style
            }}>
            {children}
        </ButtonWithHover>
    );
};