import ReactDOM from 'react-dom/client';
import './index.scss';
import './i18n';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './graphql';
import { Provider } from 'react-redux';
import { store } from './redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { App } from './App';
import { KeycloakService } from './services/KeycloakService';
import i18n from 'i18next';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Theme } from './theme';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

// @ts-ignore
export const env = (name: string) => window.ENV && window.ENV[name];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (env('REACT_APP_SENTRY_DSN')) {
  Sentry.init({
    dsn: env('REACT_APP_SENTRY_DSN'),
    environment: env('REACT_APP_SENTRY_ENV'),
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeBreadcrumb: excludeGraphQLFetch,
  });
}

KeycloakService.initKeycloak(() => {
  i18n.changeLanguage(KeycloakService.getLocale()).then(() => {
    root.render(
      <ApolloProvider client={apolloClient()}>
        <ThemeProvider theme={Theme}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </ApolloProvider>
    );
  });
});
