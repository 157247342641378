import { FC, useEffect, useState } from "react";
import sortArrows from '../../svg/icons/SortArrows.svg';
import { BreadcrumbItem } from "../Header/Breadcrumb";
import { Card } from "../Card";
import { Large } from "../Fonts/Large";
import { ButtonPrimaryFilled } from "../Button/ButtonPrimaryFilled";
import { useNavigate } from "react-router-dom";
import { AddSearchFilterBar } from "../SearchFilterBar/AddSearchFilterBar";
import { CSSProperties } from "styled-components";
import { Small1 } from "../Fonts/Small1";
import { CartSelected } from "./interfaces";
import { Colors } from "../../theme/Colors";
import { SortButton } from "./SortButton";
import { CartRow } from "./CartRow";
import { useQuery } from "@apollo/client";
import { GET_CART_DETAIL, SEARCH_CARTS } from "../../graphql/cart/queries";
import { Medium2 } from "../Fonts/Medium2";
import { Loading } from "../Loading";
import { PaginationButton } from "../Pagination/Buttons";
import { PaginationGroup } from "../Pagination/PaginationGroup";
import { Small2 } from "../Fonts/Small2";

interface CartProps {
    toggleCartSelected: (cart: CartSelected) => void
    changeBreadcrumb: (items: BreadcrumbItem[]) => void
}

export const Cart: FC<CartProps> = ({ toggleCartSelected, changeBreadcrumb }) => {
    const navigate = useNavigate();
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);
    const [filter, setFilter] = useState<string>('');

    const [idCartSelected, setIdCartSelected] = useState<string>('');

    const breadcrumbItems: BreadcrumbItem[] = [
        { label: 'Dashboards', link: '/dashboards' },
        { label: 'Carts', link: '/dashboards/carts' },
    ]

    useEffect(() => {
        changeBreadcrumb(breadcrumbItems);
    }, []);

    const { loading, error, data } = useQuery(SEARCH_CARTS, {
        // TODO: Aggiungere ordinamento lista
        variables: {
            filter: filter,
            limit: limit,
            offset: offset
        },
    });

    const { loading: loadingCartDetail, error: errorCartDetail, data: dataCartDetail } = useQuery(GET_CART_DETAIL, {
        skip: !idCartSelected,
        variables: {
            id: idCartSelected
        },
    });

    useEffect(() => {
        if (dataCartDetail) {
            const cartName = dataCartDetail.getCartDetail.summary.name

            toggleCartSelected({
                id: idCartSelected as string,
                name: cartName
            });
        }

    }, [idCartSelected, dataCartDetail]);

    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '10% 20% 10% 10% 10% 15% 10% 15%',
        gap: '1px'
    }

    const textStyle: CSSProperties = {
        color: Colors.PRE_APPROVED_GRAY,
        whiteSpace: 'break-spaces'
    }

    return (

        <Card style={{}}>
            {/* Commands */}
            <div style={{ padding: "20px", display: "flex", justifyContent: "space-between", paddingBottom: "32px" }}>
                <Large>Open Carts</Large>
                <ButtonPrimaryFilled onClick={() => navigate('/dashboards/carts')}>
                    View All
                </ButtonPrimaryFilled>
            </div>

            {/* Filters */}
            <AddSearchFilterBar
                onChangeDisplayItems={(e) => { setOffset(0); setLimit(parseFloat(e.target.value)) }}
                onChangeInputSearch={(e) => { setOffset(0); setFilter(e.target.value) }}
                style={{ padding: '11px 20px 11px 20px' }} />

            {/* Sorting */}
            <div style={{ height: '50px', padding: '10px 24px 10px 24px', ...gridContainer, borderTop: `0.5px solid #EEF0F4` }}>
                <SortButton style={{ display: 'flex', alignItems: 'center' }}>
                    <Small1 style={{ ...textStyle }}>
                        Cart
                    </Small1>
                </SortButton>
                <SortButton disabled={true}>
                    <Small1 style={{ ...textStyle }}>
                        Purpose
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Total Cost
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Number of services
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Number of Day2Ops
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Last Updated On
                    </Small1>
                </SortButton>
                <SortButton>
                    <Small1 style={{ ...textStyle }}>
                        Submitted By
                    </Small1>
                </SortButton>
                <Small1 style={{ color: Colors.PRE_APPROVED_GRAY, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    Options
                </Small1>
            </div>

            {/* Table Rows */}
            {error ? <Medium2 style={{
                textAlign: 'center',
                color: Colors.REJECTED_RED,
                borderTop: `1px solid #EEF0F4`,
                paddingTop: '10px'
            }}>
                {error.name} {error.message}</Medium2> : <></>}
            {loading ? <Loading /> : <></>}
            {data ? data.searchCarts.results.map((r: any) => (
                <CartRow
                    isSelected={r.id === idCartSelected}
                    selectCart={(id) => setIdCartSelected(id)}
                    cartData={r} />
            )) : <></>}

            <div style={{
                height: '80px',
                padding: '24px',
                borderTop: `0.5px solid #EEF0F4`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'

            }}>
                <Small2>Showing {limit} to {offset + 1} of {Math.ceil((data ? data.searchCarts.totalCount : 0) / limit)}</Small2>
                <PaginationGroup
                    limit={limit}
                    offset={offset}
                    totalCount={data ? data.searchCarts.totalCount : 0}
                    setOffset={setOffset}
                ></PaginationGroup>
            </div>
        </Card>
    )
}