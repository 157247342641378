import { FC } from "react";
import { Colors } from "../../theme/Colors";
import { Small2 } from "../Fonts/Small2";

export const DataRange: FC = () => {
    return (
        <div style={{
            width: '310px',
            height: '40px',
            borderRadius: '6px',
            backgroundColor: Colors.WHITE,
            border: `1px solid ${Colors.DARK_BLUE_GRAY}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Small2 style={{ color: Colors.DARK_BLUE_GRAY }}>August 28, 2023 - August 28, 2023</Small2>
        </div>
    )
}