import React, { FC } from 'react';
import { CSSProperties } from 'styled-components';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export interface HoverButtonProps extends ButtonProps {
  hoveredBackground: string;
  hoveredColor?: string;
  transition?: string;
}

export const Button: FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(

  ({ children, disabled, ...props }: ButtonProps, ref) => {
    const buttonStyle: CSSProperties = {
      width: '128px',
      height: '30px',
      justifyContent: 'center',
      padding: '10px 40px',
      borderRadius: '45px',
      cursor: disabled ? 'not-allowed' : 'pointer',
      opacity: disabled ? '0.6' : '1',
      display: 'flex',
      alignItems: 'center'
    };

    return (
    <button
      {...props}
      style={{ ...buttonStyle, ...props.style }}
      className={props.className ?? "Button"}
      ref={ref}>
      {children}
    </button>)
  }
);

Button.displayName = 'Button';


export const ButtonWithHover: FC<HoverButtonProps> = ({ children, hoveredBackground, hoveredColor, ...props }: HoverButtonProps) => {
  const [hovered, setHovered] = React.useState(false)

  const computedHoveredColor = hoveredColor ?? props.style?.color ?? undefined

  const buttonStyle: CSSProperties = {
    ...props.style,
    backgroundColor: hovered ? hoveredBackground : props.style?.backgroundColor,
    color: hovered ? computedHoveredColor : props.style?.color,
    transition: props.transition ?? "background-color 0.3s ease-in-out, color 0.3s ease-in-out",
  };


  return (
    <Button
      {...props}
      onMouseLeave={() => setHovered(false)}
      onMouseEnter={() => setHovered(true)}
      style={buttonStyle} >
      {children}
    </Button>
  );
};

