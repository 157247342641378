import { CSSProperties, FC, useState } from "react"
import previousLogo from '../../../../svg/icons/ArrowLeft.svg';
import nextLogo from '../../svg/icons/ArrowRight.svg';
import { Colors } from "../../../../theme/Colors";
import { Medium1 } from "../../../Fonts/Medium1";
import { PaginationButton } from "..";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> { }

export const PreviousButton: FC<ButtonProps> = ({ ...props }) => {
    return (
        <PaginationButton {...props}>
            <Medium1 style={{ color: Colors.PRE_APPROVED_GRAY }}>{'<'}</Medium1>
        </PaginationButton>
    )
}