import { FC } from "react"
import { Small1 } from "../../Fonts/Small1"
import { ProgressBar, ProgressBarStepStatus } from "../../ProgressBar"
import { ButtonYellow } from "../../Button/ButtonYellow"
import { timestampToDateString } from "../../../utils/dateUtils"
import { formatNumberAsFloat } from "../../../utils/numberUtils"
import { CSSProperties } from "styled-components"
import { ButtonPrimaryOutline } from "../../Button/ButtonPrimaryOutline"
import { ButtonOrange } from "../../Button/ButtonOrange"

interface OrderRowProps {
    orderData: any
}

export const OrderRow: FC<OrderRowProps> = ({ orderData }) => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: '15% 15% 10% 50% 6%',
        gap: '10px',
        alignItems: 'center'
    }

    let technicalApproval: ProgressBarStepStatus = ProgressBarStepStatus.WAITING_APPROVAL
    if (orderData.technicalApprovalStatus === "WAITING_APPROVAL") {
        technicalApproval = ProgressBarStepStatus.WAITING_APPROVAL
    } else if (orderData.technicalApprovalStatus === "APPROVED") {
        technicalApproval = ProgressBarStepStatus.APPROVED
    } else if (orderData.technicalApprovalStatus === "REJECTED") {
        technicalApproval = ProgressBarStepStatus.REJECTED
    } else if (orderData.technicalApprovalStatus === "PRE_APPROVED") {
        technicalApproval = ProgressBarStepStatus.PRE_APPROVED
    }

    let financialUBISStatus: ProgressBarStepStatus = ProgressBarStepStatus.WAITING_APPROVAL
    if (orderData.financialUBISStatus === "WAITING_APPROVAL") {
        financialUBISStatus = ProgressBarStepStatus.WAITING_APPROVAL
    } else if (orderData.financialUBISStatus === "APPROVED") {
        financialUBISStatus = ProgressBarStepStatus.APPROVED
    } else if (orderData.financialUBISStatus === "REJECTED") {
        financialUBISStatus = ProgressBarStepStatus.REJECTED
    } else if (orderData.financialUBISStatus === "PRE_APPROVED") {
        financialUBISStatus = ProgressBarStepStatus.PRE_APPROVED
    }

    let financialVTSStatus: ProgressBarStepStatus = ProgressBarStepStatus.WAITING_APPROVAL
    if (orderData.financialVTSStatus === "WAITING_APPROVAL") {
        financialVTSStatus = ProgressBarStepStatus.WAITING_APPROVAL
    } else if (orderData.financialVTSStatus === "APPROVED") {
        financialVTSStatus = ProgressBarStepStatus.APPROVED
    } else if (orderData.financialVTSStatus === "REJECTED") {
        financialVTSStatus = ProgressBarStepStatus.REJECTED
    } else if (orderData.financialVTSStatus === "PRE_APPROVED") {
        financialVTSStatus = ProgressBarStepStatus.PRE_APPROVED
    }

    let status: ProgressBarStepStatus = ProgressBarStepStatus.WAITING_APPROVAL
    if (orderData.status === "WAITING_APPROVALS") {
        status = ProgressBarStepStatus.WAITING_APPROVAL
    } else if (orderData.status === "IN_PROGRESS") {
        status = ProgressBarStepStatus.IN_PROGRESS
    } else if (orderData.status === "PENDING_TASKS") {
        status = ProgressBarStepStatus.PENDING_TASKS
    } else if (orderData.status === "SUCCESS") {
        status = ProgressBarStepStatus.APPROVED
    } else if (orderData.status === "REJECTED") {
        status = ProgressBarStepStatus.REJECTED
    } else if (orderData.status === "DELETED") {
        status = ProgressBarStepStatus.FAILED
    } else if (orderData.status === "FAILED") {
        status = ProgressBarStepStatus.FAILED
    } else if (orderData.status === "PARTIAL_SUCCESS") {
        status = ProgressBarStepStatus.APPROVED
    }

    const isAllowedToApprove = false // TODO: JWT Decode Roles
    const isAllowedToFix = false // TODO: JWT Decode Roles
    let button = <ButtonPrimaryOutline>Open</ButtonPrimaryOutline>
    if (orderData.technicalApprovalStatus === "WAITING_APPROVAL" ||
        orderData.financialUBISStatus === "WAITING_APPROVAL" ||
        orderData.financialVTSStatus === "WAITING_APPROVAL" ||
        orderData.status === "WAITING_APPROVALS") {
        button = <ButtonYellow
                    onClick={() => isAllowedToApprove ? {
                        // TODO: Approve
                    } : {}}
                    disabled={!isAllowedToApprove}>Approve</ButtonYellow>
    } else if (orderData.technicalApprovalStatus === "APPROVED" &&
        orderData.financialUBISStatus === "APPROVED" &&
        orderData.financialVTSStatus === "APPROVED" &&
        orderData.status === "PENDING_TASKS") {
        button = <ButtonOrange
                    onClick={() => isAllowedToFix ? {
                        // TODO: Fix
                    } : {}}
                    disabled={!isAllowedToFix}>Fix</ButtonOrange>
    }

    return (
        <div style={{ height: '78px', padding: '10px 24px 10px 24px', ...gridContainer, borderTop: `0.5px solid #EEF0F4` }}>
            <Small1 style={{ overflow: 'hidden' }}>ORDER # {orderData.orderId}</Small1>
            <Small1>{timestampToDateString(orderData.lastUpdate)}</Small1>
            <Small1>€ {formatNumberAsFloat(orderData.cost)}</Small1>
            <ProgressBar states={{
                created: ProgressBarStepStatus.APPROVED,
                technicalApproval: technicalApproval,
                financialApproval: financialUBISStatus,
                vtsApproval: financialVTSStatus,
                deploy: status,
            }} />
            <div style={{ display: 'flex', justifyContent: 'space-around', marginRight: '10px' }}>
                {button}
            </div>
        </div>
    )
}