import React, { FC } from 'react';
import deleteIcon from '../../../svg/icons/Delete.svg';
import { IconButton } from '..';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

export const IconButtonDelete : FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <IconButton {...props} icon={deleteIcon}></IconButton>
    )
}