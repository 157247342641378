import { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ErrorPage } from './components/ErrorPage';
import styled from 'styled-components';
import { Header } from './components/Header';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { Notifications } from './components/Notifications/Notifications';
import { Dashboard } from './components/Dashboard';
import { SideBar } from './components/SideBar';
import { Cart } from './components/Carts';
import { CartSelected } from './components/Carts/interfaces';
import { CartServices } from './components/Carts/Services';
import { BreadcrumbItem } from './components/Header/Breadcrumb';
import { useQuery } from '@apollo/client';
import { GET_CART_DETAIL } from './graphql/cart/queries';
import { CartReview } from './components/Carts/Review';
import { Loading } from './components/Loading';
import { ErrorToast } from './components/Toasts/ErrorToast';
import { AllOrders } from './components/Orders/AllOrders';
import { Favorites } from './components/Favorites';
import { Day2OperationsCatalog } from './components/Catalog/Day2Operations';
import { ServicesCatalog } from './components/Catalog/Services';

export const App: FC = () => {
  const params = new URLSearchParams(window.location.search);

  // SideBar
  const [isSidebarCollaped, setIsSidebarCollaped] = useState<boolean>(false);

  // Cart
  const [cartSelected, setIsCartSelected] = useState<boolean>(false);
  const [nameCartSelected, setNameCartSelected] = useState<string>();
  const [idCartSelected, setIdCartSelected] = useState<string>();

  // BreadCrumb
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([{ label: 'Dashboards', link: '/' }])

  const toggleSidebar = () => {
    setIsSidebarCollaped(!isSidebarCollaped);
  }

  const toggleCartSelected = (cart: CartSelected) => {
    setIsCartSelected(true);
    setNameCartSelected(cart.name);
    setIdCartSelected(cart.id);
  }

  const changeBreadcrumb = (items: BreadcrumbItem[]) => {
    setBreadcrumbItems(items)
  }

  const { loading, error, data } = useQuery(GET_CART_DETAIL, {
    skip: !params.get('idCart'),
    variables: {
      id: params.get('idCart')!
    },
  });

  // Cart Name
  useEffect(() => {
    if (params.get('idCart') && data) {
      toggleCartSelected({
        id: params.get('idCart')!,
        name: data.getCartDetail.summary.name
      })
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <ErrorToast text="Impossibile connettersi al server!" />;

  return (
    <Container>
      <SideBar collapsed={isSidebarCollaped} />
      <SubContainer>
        <Header
          toggleSidebar={toggleSidebar}
          nameCartSelected={nameCartSelected}
          cartSelected={cartSelected}
          idCartSelected={idCartSelected}
          breadcrumbItems={breadcrumbItems} />
        <PageContainer>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={{
                    pathname: '/dashboards',
                  }}
                />
              }
            />
            <Route
              path="/*"
              element={
                <Navigate
                  to={{
                    pathname: '/dashboards',
                  }}
                />
              }
            />
            {
              <Route
                path='/dashboards'
                element={
                  <Dashboard changeBreadcrumb={changeBreadcrumb} />
                }>
              </Route>
            },
            <Route
              path='/dashboards/carts'
              element={
                <Cart
                  toggleCartSelected={toggleCartSelected}
                  changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route
              path='/dashboards/carts/services'
              element={
                <CartServices
                  changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route
              path='/dashboards/carts/review'
              element={
                <CartReview
                  changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route
              path='/all-orders'
              element={
                <AllOrders changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route
              path='/favorites'
              element={
                <Favorites changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route
              path='/catalog/day2ops'
              element={
                <Day2OperationsCatalog changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route
              path='/catalog/services'
              element={
                <ServicesCatalog changeBreadcrumb={changeBreadcrumb} />
              }>
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Notifications />
        </PageContainer>
      </SubContainer>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const SubContainer = styled.div`
  padding: 10px;
  width: 100%;
`;

export const PageContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
