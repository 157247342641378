import { FC } from "react";
import { Small1 } from "../../Fonts/Small1";
import { CSSProperties } from "styled-components";
import { Colors } from "../../../theme/Colors";


export const HeaderProgressBar: FC = () => {
    const gridContainer: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: '0px',
        alignItems: 'center'
    }

    const textStyle: CSSProperties = {
        color: Colors.PRE_APPROVED_GRAY,
        whiteSpace: 'break-spaces'
    }

    return (
        <div style={gridContainer}>
            <Small1 style={textStyle}>Created</Small1>
            <Small1 style={textStyle}>Technical Approval</Small1>
            <Small1 style={textStyle}>Financial Approval</Small1>
            <Small1 style={textStyle}>VTS Approval</Small1>
            <Small1 style={textStyle}>Deploy</Small1>
        </div>

    )
}